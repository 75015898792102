import './App.css';
import AuthProvider from './components/provider/AuthProvider';
import { HttpClientProvider } from './components/provider/HttpClientProvider';
import Routes from './components/routes/Routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ScreenDimensionsProvider from './components/provider/ScreenDimensionsProvider';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: 10 * 1000,
            refetchOnWindowFocus: false,
        },
    },
});

function App() {
    return (
        <div className="App">
            <ScreenDimensionsProvider>
                <AuthProvider>
                    <HttpClientProvider>
                        <QueryClientProvider client={queryClient}>
                            <Routes />
                        </QueryClientProvider>
                    </HttpClientProvider>
                </AuthProvider>
            </ScreenDimensionsProvider>
        </div>
    );
}

export default App;
