let ipcRenderer;
if (!!window.require) {
    const { ipcRenderer: tempRendered } = window.require('electron');
    ipcRenderer = tempRendered;
}

export const EN_LANG = 'EN';
export const KA_LANG = 'KA';

const DEFAULT_LANGUAGES = [EN_LANG];

const MINUTES_REMAINING_NOTIFICATION_TEXTS = {
    title: {
        [EN_LANG]: 'Your Session Will Expire Soon',
        [KA_LANG]: 'თქვენი სესია მალე დასრულდება',
    },
    message: {
        [EN_LANG]: ' Minutes Remaining',
        [KA_LANG]: ' წუთი დასრულებამდე',
    },
};

const SESSION_EXPIRED_TEXTS = {
    title: {
        [EN_LANG]: 'Your Session Expired',
        [KA_LANG]: 'დრო ამოიწურა',
    },
    message: {
        [EN_LANG]: 'Please go to the desk',
        [KA_LANG]: 'მიმართეთ ადმინისტრატორს',
    },
};

const OVERLAY_NOTIFICATION_TEXTS = {
    title: {
        [EN_LANG]: 'Session Expired',
        [KA_LANG]: 'სესია დასრულდა',
    },
    message: {
        [EN_LANG]: 'Session Expired',
        [KA_LANG]: 'სესია დასრულდა',
    },
};

export const sendOverlayNotification = (address, languages = DEFAULT_LANGUAGES) => {
    if (!isOverlayConfigured(address)) {
        return;
    }

    if (!languages.length) {
        languages = DEFAULT_LANGUAGES;
    }

    const title = languages.map((lang) => OVERLAY_NOTIFICATION_TEXTS.title[lang]).join('\n');
    const message = languages.map((lang) => OVERLAY_NOTIFICATION_TEXTS.message[lang]).join('\n');

    ipcRenderer.send('request-proxy', [
        getOverlayRequest(address, 95),
        getOverlayNotificationConfigRequest(address),
        {
            url: 'http://' + address + '/notify',
            request: {
                method: 'post',
                body: {
                    id: 0,
                    title:
                        title +
                        '                                                                                                                                                                                                                                                                                                                   ' +
                        '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
                    message: '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n' + message,
                    largeIcon: 'mdi:timer-sand-empty',
                    corner: 'top_start',
                    duration: 60,
                },
            },
        },
    ]);
};

export const sendTimerWarningNotification = (
    address,
    timeDuration,
    languages = DEFAULT_LANGUAGES
) => {
    if (!isOverlayConfigured(address)) {
        return;
    }

    if (!languages.length) {
        languages = DEFAULT_LANGUAGES;
    }

    const title = languages
        .map((lang) => MINUTES_REMAINING_NOTIFICATION_TEXTS.title[lang])
        .join('\n');
    const message = languages
        .map((lang) => timeDuration + MINUTES_REMAINING_NOTIFICATION_TEXTS.message[lang])
        .join('\n');

    ipcRenderer.send('request-proxy', [
        getTimerNotificationConfigRequest(address),
        {
            url: 'http://' + address + '/notify',
            request: {
                method: 'post',
                body: {
                    id: 1,
                    title,
                    message,
                    largeIcon: 'mdi:timer-sand-complete',
                    corner: 'top_end',
                    duration: 15,
                },
            },
        },
    ]);
};

export const sendSessionExpiredWarningNotification = (address, languages = DEFAULT_LANGUAGES) => {
    if (!isOverlayConfigured(address)) {
        return;
    }

    if (!languages.length) {
        languages = DEFAULT_LANGUAGES;
    }

    const title = languages.map((lang) => SESSION_EXPIRED_TEXTS.title[lang]).join('\n');
    const message = languages.map((lang) => SESSION_EXPIRED_TEXTS.message[lang]).join('\n');

    ipcRenderer.send('request-proxy', [
        getTimerNotificationConfigRequest(address),
        {
            url: 'http://' + address + '/notify',
            request: {
                method: 'post',
                body: {
                    id: 1,
                    title,
                    message,
                    largeIcon: 'mdi:timer-sand-complete',
                    corner: 'top_end',
                    duration: 15,
                },
            },
        },
    ]);
};

export const sendClearAllNotifications = (address) => {
    if (!isOverlayConfigured(address)) {
        return;
    }

    ipcRenderer.send('request-proxy', [
        getOverlayRequest(address, 0),
        {
            url: 'http://' + address + '/notify',
            request: {
                method: 'post',
                body: {
                    id: 0,
                    message: 'Clearing',
                    duration: 0,
                },
            },
        },
        getTimerNotificationConfigRequest(address),
    ]);
};

const isOverlayConfigured = (address) => {
    return ipcRenderer && address;
};

const getOverlayRequest = (address, overlayPercent) => {
    return {
        url: 'http://' + address + '/set/overlay',
        request: {
            method: 'post',
            body: {
                overlayVisibility: overlayPercent,
            },
        },
    };
};

const getTimerNotificationConfigRequest = (address) => {
    return {
        url: 'http://' + address + '/set/notifications',
        request: {
            method: 'post',
            body: {
                notificationLayoutName: 'Timer Notification',
            },
        },
    };
};

const getOverlayNotificationConfigRequest = (address) => {
    return {
        url: 'http://' + address + '/set/notifications',
        request: {
            method: 'post',
            body: {
                notificationLayoutName: 'Overlay Notification',
            },
        },
    };
};
