import ChangeCalculator from '../ChangeCalculator/ChangeCalculator';
import { LocalAtm, CreditCard } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import './PaymentMethodInputs.css';

function PaymentMethodInputs({
    maxTotalValue = 100000.0,
    value,
    strictMode,
    onInput,
    showChangeCalculator = true,
}) {
    const [payments, setPayments] = useState({
        cash: parseFloat(value?.cash ? value.cash : 0),
        credit: parseFloat(value?.credit ? value.credit : 0),
    });

    const parsedMaxTotalValue = useMemo(() => {
        return Math.max(parseFloat(maxTotalValue), 0);
    }, [maxTotalValue]);

    useEffect(() => {
        if (strictMode) {
            if (payments.credit === 0) {
                setPayments({ cash: parsedMaxTotalValue, credit: 0 });
            } else if (payments.cash === 0) {
                setPayments({ cash: 0, credit: parsedMaxTotalValue });
            } else {
                setPayments({ cash: parsedMaxTotalValue, credit: 0 });
            }
        } else {
            setPayments((curState) => {
                return {
                    cash: Math.min(parseFloat(curState.cash), parsedMaxTotalValue),
                    credit: Math.min(parseFloat(curState.credit), parsedMaxTotalValue),
                };
            });
        }
    }, [parsedMaxTotalValue, strictMode]); // eslint-disable-line

    const handleChange = useCallback(
        (e) => {
            const changedField = e.target.name;
            const secondField = changedField === 'cash' ? 'credit' : 'cash';

            const eventValue = parseFloat(e.target.value?.replace(/^0+/, ''));

            const newValue =
                eventValue > parsedMaxTotalValue
                    ? parsedMaxTotalValue
                    : eventValue
                      ? eventValue
                      : 0;

            setPayments((curState) => {
                return {
                    [changedField]: newValue,
                    [secondField]: strictMode
                        ? parsedMaxTotalValue - newValue
                        : curState[secondField],
                };
            });
        },
        [parsedMaxTotalValue, strictMode]
    );

    const handleModifyAmountKeyDown = useCallback((e) => {
        if (e.key === '-' || e.key === 'e') {
            e.preventDefault();
        }
    }, []);

    useEffect(() => {
        onInput(payments);
    }, [payments, onInput]);

    return (
        <>
            <div className="paymentTypeInputs">
                <div className="paymentMethodInput">
                    <label htmlFor="cash" className="paymentTypeLabel">
                        CASH <LocalAtm className="paymentTypeIcon" />
                    </label>
                    <input
                        type="number"
                        id="cash"
                        name="cash"
                        value={payments.cash.toString()}
                        onChange={handleChange}
                        className="payment-input"
                        onKeyDown={handleModifyAmountKeyDown}
                    />
                </div>
                <div className="paymentMethodInput">
                    <label htmlFor="credit" className="paymentTypeLabel">
                        CREDIT <CreditCard className="paymentTypeIcon" />
                    </label>
                    <input
                        type="number"
                        id="credit"
                        name="credit"
                        value={payments.credit.toString()}
                        onChange={handleChange}
                        onKeyDown={handleModifyAmountKeyDown}
                        className="payment-input"
                    />
                    {showChangeCalculator ? <ChangeCalculator priceToPay={payments.cash} /> : null}
                </div>
            </div>
        </>
    );
}

export default PaymentMethodInputs;
