import { Backdrop, CircularProgress } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import '../RentalSessionPopUps.css';
import './RentalSessionEndPopUp.css';
import PaymentMethodInputs from '../../../shared/PaymentMethodInputs/PaymentMethodInputs';
import { useOrgOptions } from '../../../provider/OrganizationOptionsProvider';
import { calculateMenuPrice, calculatePrice, roundValue } from '../../rentalSessionUtils';
import {
    useGetNestedLinkedRentalSessions,
    useRentalCategories,
    useRentals,
} from '../../../provider/RentalSessionsWrapperProvider';
import { sendClearAllNotifications } from '../../../shared/utils/tvOverlayUtils';

const emptyResult = [];

// TODO Too many re-renders on Mimdinare case
function RentalSessionEndPopUp({
    setEndPopUp,
    session,
    totalPrice,
    endSession,
    prepaidBalance,
    updateExpiredSessions,
    timestamp,
    onError,
}) {
    const { categoriesMap } = useRentalCategories();
    const orgOptions = useOrgOptions();
    const [payments, setPayments] = useState({
        cash: 0,
        credit: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const { currency } = useOrgOptions();
    const { rentalsById } = useRentals();

    const { data: linkedRentalSessions = emptyResult } = useGetNestedLinkedRentalSessions(
        session.linkedRentalSessions,
        onError
    );

    const linkedSessionsPrices = useMemo(() => {
        const prices = {};
        prices.totalPrice = linkedRentalSessions.length
            ? linkedRentalSessions.reduce((previousSum, linkedSession) => {
                  const sessionPrice =
                      parseFloat(
                          calculatePrice(
                              (linkedSession.endTimestamp - linkedSession.startTimestamp) /
                                  1000 /
                                  60,
                              linkedSession.rentalCategoryId,
                              linkedSession.rentalTypes,
                              linkedSession.transferHistory,
                              categoriesMap
                          )
                      ) +
                      parseFloat(calculateMenuPrice(linkedSession.usedProducts)) -
                      linkedSession.paymentCash.$numberDecimal -
                      linkedSession.paymentCash.$numberDecimal;

                  prices[linkedSession._id] = sessionPrice;

                  return previousSum + sessionPrice;
              }, 0)
            : 0;

        return prices;
    }, [categoriesMap, linkedRentalSessions]);

    const curTotalPrice = useMemo(() => {
        return parseFloat(
            parseFloat(totalPrice) -
                parseFloat(prepaidBalance.totalPrepaid) +
                roundValue(linkedSessionsPrices.totalPrice, orgOptions)
        );
    }, [linkedSessionsPrices.totalPrice, orgOptions, prepaidBalance.totalPrepaid, totalPrice]);

    const isPaymentEnough = useMemo(() => {
        return (
            parseFloat(payments.cash) + parseFloat(payments.credit) === curTotalPrice ||
            (curTotalPrice < 0 &&
                parseFloat(payments.cash) + parseFloat(payments.credit) > curTotalPrice)
        );
    }, [curTotalPrice, payments.cash, payments.credit]);

    const handlePaymentsInput = useCallback(
        (paymentInputs) => {
            setPayments({ ...paymentInputs });
        },
        [setPayments]
    );

    const handleEnd = useCallback(async () => {
        setIsLoading(true);

        let paymentCash = payments.cash;
        let paymentCredit = payments.credit;

        const linkedRentalSessionsPayments = Object.keys(linkedSessionsPrices)
            .filter((key) => key !== 'totalPrice')
            .map((sessionId) => {
                const curLinkedPrice = linkedSessionsPrices[sessionId];
                const linkedSessionPayments = { sessionId };

                paymentCash -= curLinkedPrice;

                if (paymentCash < 0) {
                    linkedSessionPayments.paymentCash = curLinkedPrice + paymentCash;
                    linkedSessionPayments.paymentCredit = -paymentCash;

                    paymentCredit += paymentCash;
                    paymentCash = 0;
                } else {
                    linkedSessionPayments.paymentCash = curLinkedPrice;
                    linkedSessionPayments.paymentCredit = 0;
                }

                return linkedSessionPayments;
            });

        if (
            await endSession({
                sessionId: session._id,
                endTimestamp: timestamp,
                paymentCash,
                paymentCredit,
                linkedRentalSessionsPayments,
            })
        ) {
            sendClearAllNotifications(rentalsById[session.rentalId]?.address);
            setEndPopUp(false);
            updateExpiredSessions(session, 'REMOVE');
        }
        setIsLoading(false);
    }, [
        payments.cash,
        payments.credit,
        linkedSessionsPrices,
        endSession,
        session,
        timestamp,
        rentalsById,
        setEndPopUp,
        updateExpiredSessions,
    ]);

    const handleCancel = useCallback(() => {
        if (!isLoading) {
            setEndPopUp(false);
        }
    }, [isLoading, setEndPopUp]);

    return (
        <>
            <div className="popUp-wrapper rentalCard-sessionEnd">
                <div className="totalPriceSection">
                    <span>Total Price : </span>
                    <span className="endPopUp-priceIndicators">
                        {totalPrice}
                        {currency.sign}
                    </span>
                </div>

                {session.linkedRentalSessions.length ? (
                    <div className="totalPriceSection">
                        <span>Linked Sessions price : </span>
                        <span className="endPopUp-priceIndicators">
                            {parseFloat(linkedSessionsPrices.totalPrice).toFixed(2)}
                            {currency.sign}
                        </span>
                    </div>
                ) : null}

                <div className="totalPriceSection">
                    <span>Price to Pay : </span>
                    <span className="endPopUp-priceIndicators">
                        {curTotalPrice.toFixed(2)}
                        {currency.sign}
                    </span>
                </div>
                <PaymentMethodInputs
                    maxTotalValue={curTotalPrice}
                    onInput={handlePaymentsInput}
                    strictMode={true}
                />
                <div className="sessionPopUp-actions">
                    <button
                        className="popUp-button cancel"
                        disabled={isLoading}
                        onClick={handleCancel}>
                        Cancel
                    </button>
                    <button
                        className="popUp-button start"
                        onClick={handleEnd}
                        disabled={isLoading || !isPaymentEnough}>
                        Done {isLoading ? <CircularProgress className="circularProgress" /> : null}
                    </button>
                </div>
            </div>

            <Backdrop className="alert-backdrop" open={true} onClick={handleCancel} />
        </>
    );
}

export default RentalSessionEndPopUp;
