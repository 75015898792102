import { Alert as MuiAlert, Box, Drawer, IconButton, Stack } from '@mui/material';
import './ExpiredAlert.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FLASHING_INTERVAL } from '../timers/TimerConstants';
import { VolumeOff, VolumeUp, Warning } from '@mui/icons-material';
import { useStopwatch } from 'react-timer-hook';
import { calculateOffset, calculatePauseDuration, formatTime } from '../rentalSessionUtils';
import {
    sendOverlayNotification,
    sendSessionExpiredWarningNotification,
} from '../../shared/utils/tvOverlayUtils';
import { useRentals } from '../../provider/RentalSessionsWrapperProvider';
import List from '@mui/material/List';
import { useScreenDimensions } from '../../provider/ScreenDimensionsProvider';
import { useOrgOptions } from '../../provider/OrganizationOptionsProvider';

function ExpiredAlert({ expiredSessions, handleClick }) {
    const [isFlashing, setIsFlashing] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const [savedExpiredSessions, setSavedExpiredSessions] = useState({ ...expiredSessions });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { screenWidth } = useScreenDimensions();
    const expiredAlertAudio = useMemo(() => {
        return new Audio('alertSound.mp3');
    }, []);

    useEffect(() => {
        for (let key of Object.keys(expiredSessions)) {
            if (!savedExpiredSessions[key]) {
                setIsMuted(false);
                break;
            }
        }

        setSavedExpiredSessions(expiredSessions);

        if (Object.keys(expiredSessions).length) {
            const interval = setInterval(() => {
                setIsFlashing((curState) => !curState);
            }, FLASHING_INTERVAL);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expiredSessions]);

    useEffect(() => {
        const shouldAudioPlay = () => {
            return !isMuted && Object.keys(expiredSessions).length;
        };

        if (shouldAudioPlay()) {
            const interval = setInterval(() => {
                if (shouldAudioPlay()) {
                    expiredAlertAudio.play();
                }
            }, FLASHING_INTERVAL);
            return () => clearInterval(interval);
        }
    }, [isMuted, expiredSessions, expiredAlertAudio]);

    const toggleAlertsDrawer = useCallback(
        (anchor, open) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }

            setIsDrawerOpen(open);
        },
        []
    );

    const drawerContent = useCallback(
        (anchor) => (
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
                role="presentation"
                onClick={toggleAlertsDrawer(anchor, false)}
                onKeyDown={toggleAlertsDrawer(anchor, false)}>
                <List>
                    {isMuted ? (
                        <VolumeOff
                            className="expiredSessions-toggleVolume"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsMuted(false);
                            }}
                        />
                    ) : (
                        <VolumeUp
                            className="expiredSessions-toggleVolume"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsMuted(true);
                            }}
                        />
                    )}
                    <Stack spacing={1} className="expiredSessions-stack">
                        {Object.keys(expiredSessions).map((rentalId) => (
                            <AlertItem
                                expiredSession={expiredSessions[rentalId]}
                                handleClick={handleClick}
                                key={rentalId}
                            />
                        ))}
                    </Stack>
                </List>
            </Box>
        ),
        [expiredSessions, handleClick, isMuted, toggleAlertsDrawer]
    );

    if (Object.keys(expiredSessions).length) {
        return screenWidth > 500 ? (
            <MuiAlert
                variant="outlined"
                severity="warning"
                className={`expiredSessions-container ` + (isFlashing ? 'white' : 'red')}>
                {isMuted ? (
                    <VolumeOff
                        className="expiredSessions-toggleVolume"
                        onClick={() => setIsMuted(false)}
                    />
                ) : (
                    <VolumeUp
                        className="expiredSessions-toggleVolume"
                        onClick={() => setIsMuted(true)}
                    />
                )}
                <span>{Object.keys(expiredSessions).length} Session(s) has been expired</span>
                <Stack spacing={3} className="expiredSessions-stack">
                    {Object.keys(expiredSessions).map((rentalId) => (
                        <AlertItem
                            expiredSession={expiredSessions[rentalId]}
                            handleClick={handleClick}
                            key={rentalId}
                        />
                    ))}
                </Stack>
            </MuiAlert>
        ) : (
            <>
                <IconButton
                    className={'expiredSession-drawer-toggle'}
                    onClick={toggleAlertsDrawer('right', true)}>
                    <Warning className={'drawer-toggle-icon'} />
                </IconButton>
                <Drawer
                    anchor={'right'}
                    open={isDrawerOpen}
                    onClose={toggleAlertsDrawer('right', false)}>
                    {drawerContent('right')}
                </Drawer>
            </>
        );
    } else {
        return null;
    }
}

function AlertItem({ expiredSession, handleClick }) {
    const orgOptions = useOrgOptions();
    const { seconds, minutes, hours } = useStopwatch({
        autoStart: true,
        offsetTimestamp: calculateOffset(
            parseInt(expiredSession.endTimestamp) +
                calculatePauseDuration(Date.now(), expiredSession.pauses)
        ),
    });

    const { rentalsById } = useRentals();

    useEffect(() => {
        if (
            hours * 60 + minutes >= (orgOptions.overlay?.expiredStartTime ?? 10) &&
            seconds % 30 === 0
        ) {
            sendOverlayNotification(
                rentalsById[expiredSession.rentalId]?.address,
                orgOptions.overlay?.languages
            );
        }

        // Takes ~2 second before alert is displayed
        if (hours === 0 && minutes === 0 && seconds === 5) {
            sendSessionExpiredWarningNotification(
                rentalsById[expiredSession.rentalId]?.address,
                orgOptions.overlay?.languages
            );
        }
    }, [
        rentalsById,
        expiredSession.rentalId,
        hours,
        minutes,
        seconds,
        orgOptions.overlay?.languages,
        orgOptions.overlay?.expiredStartTime,
    ]);

    return (
        <MuiAlert
            onClick={() => handleClick(expiredSession)}
            className="expiredSessions-item"
            variant="filled"
            severity="error">
            <div className="expiredSession-info">
                {expiredSession.rentalName}
                <div>
                    <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:
                    <span>{formatTime(seconds)}</span>
                </div>
            </div>
        </MuiAlert>
    );
}

export default ExpiredAlert;
