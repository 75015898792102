import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOrgOptions } from '../../../../provider/OrganizationOptionsProvider';
import { calculateMenuPrice, isFixedType, roundValue } from '../../../rentalSessionUtils';
import SessionMenu from '../../../SessionMenu/SessionMenu';
import FixedTimer from '../../../timers/FixedTimer';
import MimdinareTimer from '../../../timers/MimdinareTimer';
import { Backdrop, IconButton } from '@mui/material';
import {
    Add,
    AddCircleOutline,
    PauseCircleOutlineOutlined,
    PlayCircleOutlined,
} from '@mui/icons-material';
import ProductSales from '../../../../ProductSales/ProductSales';
import RentalSessionEndPopUp from '../../../RentalSessionPopUps/EndPopUp/RentalSessionEndPopUp';
import RentalSessionAdditionalTimePopUp from '../../../RentalSessionPopUps/AdditionalTimePopUp/RentalSessionAdditionalTimePopUp';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { orange } from '@mui/material/colors';
import {
    useAddTimeRentalSession,
    useEndRentalSession,
    useLinkRentalSession,
    usePauseRentalSession,
    useProductSaleRentalSession,
    useRentals,
    useResumeRentalSession,
    useTransferRentalSession,
    useUpdateRentalSession,
} from '../../../../provider/RentalSessionsWrapperProvider';
import {
    FIXED_TRANSFER_TYPE,
    TO_CONTINUES_TRANSFER_TYPE,
} from '../../../../shared/utils/constants';

function ActiveRentalSessionCard({ session, updateExpiredSessions, onError }) {
    const { mutateAsync: end } = useEndRentalSession(onError);
    const { mutateAsync: edit } = useUpdateRentalSession(onError);
    const { mutateAsync: transfer } = useTransferRentalSession(onError);
    const { mutateAsync: pause } = usePauseRentalSession(onError);
    const { mutateAsync: resume } = useResumeRentalSession(onError);
    const { mutateAsync: link } = useLinkRentalSession(onError);
    const { mutateAsync: sale } = useProductSaleRentalSession(onError);
    const { mutateAsync: addTime } = useAddTimeRentalSession(onError);

    const [timerPrice, setTimerPrice] = useState(0);

    const [additionalTimePopUp, setAdditionalTimePopUp] = useState(false);

    const [isExpired, setIsExpired] = useState(false);

    const [isProductSale, setIsProductSale] = useState(false);

    const [isPaused, setIsPaused] = useState(false);

    const [endPopUp, setEndPopUp] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [tempEndPopUpState, setTempEndPopUpState] = useState({});

    const orgOptions = useOrgOptions();

    const { rentalsById } = useRentals();

    const pauseDuration = useMemo(() => {
        if (session.pauses) {
            let duration = 0;
            for (let pause of session.pauses) {
                if (!pause.endTimestamp) {
                    duration += Date.now() - pause.startTimestamp;
                    setIsPaused(true);
                    return duration;
                } else {
                    duration += parseInt(pause.endTimestamp) - parseInt(pause.startTimestamp);
                }
            }
            setIsPaused(false);
            return duration;
        }
        return 0;
    }, [session.pauses]);

    const menuPrice = useMemo(() => {
        return calculateMenuPrice(session.usedProducts);
    }, [session.usedProducts]);

    const prePaidBalance = useMemo(() => {
        const totalPrepaidCash = parseFloat(session.paymentCash.$numberDecimal);
        const totalPrepaidCredit = parseFloat(session.paymentCredit.$numberDecimal);
        const totalPrepaid = totalPrepaidCash + totalPrepaidCredit;

        return {
            totalPrepaid,
            totalPrepaidCash,
            totalPrepaidCredit,
        };
    }, [session.paymentCash, session.paymentCredit]);

    const totalPrice = useMemo(
        () => (roundValue(timerPrice, orgOptions) + parseFloat(menuPrice)).toFixed(2),
        [menuPrice, timerPrice, orgOptions]
    );

    useEffect(() => {
        if (isExpired) {
            updateExpiredSessions(session, 'ADD');
        } else {
            updateExpiredSessions(session, 'REMOVE');
        }
    }, [isExpired, updateExpiredSessions, session]);

    const handlePause = useCallback(async () => {
        if (window.confirm('Are you sure you want to Pause this session?')) {
            await pause({
                sessionId: session._id,
                timestamp: Date.now(),

                categoryId: session.rentalCategoryId,
                rentalId: session.rentalId,
            });
            setIsPaused(true);
        }
    }, [pause, setIsPaused, session._id, session.rentalCategoryId, session.rentalId]);

    const handleResume = useCallback(async () => {
        if (window.confirm('Are you sure you want to Resume this session?')) {
            await resume({
                sessionId: session._id,
                timestamp: Date.now(),

                categoryId: session.rentalCategoryId,
                rentalId: session.rentalId,
            });
            setIsPaused(false);
        }
    }, [resume, setIsPaused, session._id, session.rentalId, session.rentalCategoryId]);

    const handleAddTime = useCallback(
        async (duration, paymentCash, paymentCredit) => {
            if (
                await addTime({
                    sessionId: session._id,
                    endTimestamp: parseInt(session.endTimestamp) + duration * 60000,
                    prePaidCash: paymentCash,
                    prePaidCredit: paymentCredit,

                    categoryId: session.rentalCategoryId,
                    rentalId: session.rentalId,
                })
            ) {
                return true;
            }
        },
        [session, addTime]
    );

    const handleEdit = useCallback(
        (timestamp, types, message) => {
            return edit({
                sessionId: session._id,
                startTimestamp: timestamp,
                rentalTypeIds: types,
                message: message,

                categoryId: session.rentalCategoryId,
                rentalId: session.rentalId,
            });
        },
        [edit, session._id, session.rentalCategoryId, session.rentalId]
    );

    const handleTransfer = useCallback(
        (transferType, rentalId, duration, rentalTypeIds, prePaidCash, prePaidCredit) => {
            const timestamp = Date.now();
            return transfer({
                sessionId: session._id,
                transferType,
                rentalId,
                startTimestamp: timestamp,
                endTimestamp:
                    transferType === FIXED_TRANSFER_TYPE ? timestamp + duration * 60 * 1000 : null,
                rentalTypeIds,
                prePaidCash,
                prePaidCredit,
            });
        },
        [session._id, transfer]
    );

    const handleToMimdinare = useCallback(
        (prePaidCash, prePaidCredit) => {
            return transfer({
                sessionId: session._id,
                transferType: TO_CONTINUES_TRANSFER_TYPE,
                rentalId: session.rentalId,
                startTimestamp: Date.now(),
                prePaidCash,
                prePaidCredit,
            });
        },
        [session._id, session.rentalId, transfer]
    );

    const handleLink = useCallback(
        (sessionId, linkedSessionId, paymentCash, paymentCredit) => {
            return link({
                sessionId,
                linkedSessionId,
                endTimestamp: Date.now(),
                paymentCash,
                paymentCredit,
            });
        },
        [link]
    );

    const handleAddTimeButtonClick = useCallback(() => {
        setAdditionalTimePopUp(true);
    }, [setAdditionalTimePopUp]);

    const handleEndButtonClick = useCallback(() => {
        setTempEndPopUpState({ totalPrice, endTimestamp: Date.now() });
        setEndPopUp(true);
    }, [setEndPopUp, totalPrice, setTempEndPopUpState]);

    return (
        <>
            <div className="rentalCard-left">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span className="rentalCard-name">{session.rentalName}</span>
                    <SessionMenu
                        session={session}
                        onEdit={handleEdit}
                        onTransfer={handleTransfer}
                        onToMimdinare={handleToMimdinare}
                        onLink={handleLink}
                        onError={onError}
                    />
                </div>
                <div className="rentalCard-liveSession-controlPanel">
                    <div style={{ display: 'flex', gap: '0.3rem' }}>
                        {isFixedType(session.priceType) ? (
                            <FixedTimer
                                originalEndTimestamp={session.endTimestamp}
                                startTimestamp={session.startTimestamp}
                                setTotalPrice={setTimerPrice}
                                totalPrice={timerPrice}
                                rentalTypes={session.rentalTypes}
                                isPaused={isPaused}
                                setIsExpired={setIsExpired}
                                isExpired={isExpired}
                                endTimestamp={parseInt(session.endTimestamp) + pauseDuration}
                                categoryId={session.rentalCategoryId}
                                transferHistory={session.transferHistory}
                                rentalAddress={rentalsById[session.rentalId]?.address}
                            />
                        ) : (
                            <MimdinareTimer
                                startTimestamp={parseInt(session.startTimestamp) + pauseDuration}
                                rentalTypes={session.rentalTypes}
                                setTotalPrice={setTimerPrice}
                                totalPrice={timerPrice}
                                isPaused={isPaused}
                                transferHistory={session.transferHistory}
                                categoryId={session.rentalCategoryId}
                                rentalAddress={rentalsById[session.rentalId]?.address}
                            />
                        )}
                        <div className="session-time-actions">
                            <div className="session-time-actions-items">
                                {isFixedType(session.priceType) ? (
                                    <IconButton
                                        className="session-time-actions-item"
                                        onClick={handleAddTimeButtonClick}>
                                        <AddCircleOutline />
                                    </IconButton>
                                ) : null}

                                {isPaused ? (
                                    <IconButton
                                        className="session-time-actions-item"
                                        onClick={handleResume}>
                                        <PlayCircleOutlined />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        disabled={isExpired}
                                        className="session-time-actions-item pause"
                                        onClick={handlePause}>
                                        <PauseCircleOutlineOutlined />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="rentalCard-liveSession-finish">
                        <ColorButton onClick={handleEndButtonClick} variant="contained">
                            END
                        </ColorButton>
                    </div>
                </div>
            </div>
            <div className="rentalCard-menu">
                <div className="rentalCard-menu-container">
                    {session.usedProducts
                        ? session.usedProducts.map((product) => (
                              <div key={product._id} className="rentalCard-menu-item">
                                  <span>{product.name}</span>
                                  <span>{product.quantity}</span>
                              </div>
                          ))
                        : null}
                    {isProductSale ? (
                        <>
                            <div className="rentalSession-sessionProductSale-background">
                                <div className="rentalSession-sessionProductSale">
                                    <ProductSales
                                        onSessionSale={(products) =>
                                            sale({ sessionId: session._id, products })
                                        }
                                        setIsOpen={setIsProductSale}
                                        setIsLoading={setIsLoading}
                                        isLoading={isLoading}
                                    />
                                </div>
                            </div>
                            <Backdrop
                                className="alert-backdrop"
                                open={true}
                                onClick={() => (!isLoading ? setIsProductSale(false) : null)}
                            />
                        </>
                    ) : null}
                </div>
                <IconButton
                    className="rentalCard-menu-action"
                    onClick={() => setIsProductSale(true)}>
                    <Add />
                </IconButton>
            </div>

            {endPopUp ? (
                <RentalSessionEndPopUp
                    setEndPopUp={setEndPopUp}
                    session={session}
                    totalPrice={tempEndPopUpState.totalPrice}
                    endSession={end}
                    updateExpiredSessions={updateExpiredSessions}
                    prepaidBalance={prePaidBalance}
                    timestamp={tempEndPopUpState.endTimestamp}
                    onError={onError}
                />
            ) : null}

            {additionalTimePopUp ? (
                <RentalSessionAdditionalTimePopUp
                    handleAddTime={handleAddTime}
                    setAdditionalTimePopUp={setAdditionalTimePopUp}
                    rentalTypes={session.rentalTypes}
                    curPrice={timerPrice}
                    prepaidBalance={prePaidBalance}
                    totalPrice={totalPrice}
                    curDuration={
                        (parseFloat(session.endTimestamp) - parseFloat(session.startTimestamp)) /
                        (1000 * 60)
                    }
                    transferHistory={session.transferHistory}
                    categoryId={session.rentalCategoryId}
                    rentalAddress={rentalsById[session.rentalId]?.address}
                />
            ) : null}
        </>
    );
}

export default ActiveRentalSessionCard;

const ColorButton = styled(Button)(() => ({
    backgroundColor: orange[700],
    fontSize: '1rem',
    width: '3rem',
    height: '3rem',
    minWidth: 0,
    borderRadius: '100%',
    '&:hover': {
        backgroundColor: orange[500],
    },
}));
