import { Navigate } from 'react-router-dom';
import { useAuth } from '../provider/AuthProvider';
import PieChartWidget from './PieChartWidget/PieChartWidget';
import BarChartWidget from './BarChartWidget/BarChartWidget';
import './Dashboard.css';
import LatencyBarChartWidget from './LatencyBarChartWidget/LatencyBarChartWidget';
import { SMALL_SCREEN_SIZE, useScreenDimensions } from '../provider/ScreenDimensionsProvider';

function Dashboard() {
    const { isAdmin } = useAuth();
    const { screenWidth } = useScreenDimensions();

    if (!isAdmin) {
        return <Navigate to="/" />;
    }

    if (screenWidth <= SMALL_SCREEN_SIZE) {
        return (
            <div className={'notSupported-dashboard'}>
                Dashboard is not supported on this device
            </div>
        );
    }

    return (
        <div className="dashboard-wrapper-background">
            <div className="dashboard-wrapper">
                <PieChartWidget />
                <BarChartWidget />
            </div>
            <div className={'dashboard-bottom-wrapper'}>
                <LatencyBarChartWidget />
            </div>
        </div>
    );
}

export default Dashboard;
