import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useAuth } from '../provider/AuthProvider';
import { ProtectedRoute } from './ProtectedRoute';
import { ConfigurationProtectedRoute } from './ConfigurationProtectedRoute';
import Auth from '../Auth/Auth';
import ProductTable from '../ProductTable/ProductTable';
import RentalTable from '../RentalTable/RentalTable';
import RentalCategoryTable from '../RentalCategoryTable/RentalCategoryTable';
import ProductStockControlTable from '../ProductStockControlTable/ProductStockControlTable';
import Dashboard from '../Dashboard/Dashboard';
import UserTable from '../UserTable/UserTable';
import RentalSessionsWrapper from '../RentalSessions/RentalSessionsWrapper';
import RentalSessionHistoryTable from '../shared/History/RentalSessionHistoryTable';
import HistoryWrapper from '../shared/History/HistoryWrapper';
import CashRegistryHistoryTable from '../shared/History/CashRegistryHistoryTable';
import MenuSaleHistoryTable from '../shared/History/MenuSaleHistoryTable';

const Routes = () => {
    const { token } = useAuth();

    // Define public routes accessible to all users
    const routesForPublic = [];

    // Define routes accessible only to authenticated users
    const routesForAuthenticatedOnly = [
        {
            path: '/',
            element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
            children: [
                {
                    path: 'login',
                    element: <RentalSessionsWrapper />,
                },
                {
                    path: '',
                    element: <RentalSessionsWrapper />,
                },
                {
                    path: 'sessions',
                    element: <RentalSessionsWrapper />,
                },
                {
                    path: 'configuration',
                    element: <ConfigurationProtectedRoute />,
                    children: [
                        {
                            path: '',
                            element: <RentalTable />,
                        },
                        {
                            path: 'rental',
                            element: <RentalTable />,
                        },
                        {
                            path: 'product',
                            element: <ProductTable />,
                        },
                        {
                            path: 'rental/category',
                            element: <RentalCategoryTable />,
                        },
                        {
                            path: 'user',
                            element: <UserTable />,
                        },
                    ],
                },
                {
                    path: 'stock',
                    element: <ProductStockControlTable />,
                },
                {
                    path: 'history',
                    element: <HistoryWrapper />,
                    children: [
                        {
                            path: '',
                            element: <RentalSessionHistoryTable />,
                        },
                        {
                            path: 'rentalSession',
                            element: <RentalSessionHistoryTable />,
                        },
                        {
                            path: 'cashregistry',
                            element: <CashRegistryHistoryTable />,
                        },
                        {
                            path: 'menusale',
                            element: <MenuSaleHistoryTable />,
                        },
                    ],
                },
                {
                    path: 'dashboard',
                    element: <Dashboard />,
                },
            ],
        },
    ];

    // Define routes accessible only to non-authenticated users
    const routesForNotAuthenticatedOnly = [
        {
            path: '/',
            element: <Auth />,
        },
        {
            path: '/login',
            element: <Auth />,
        },
    ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
        ...routesForPublic,
        ...(!token ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
};

export default Routes;
