import { Backdrop, CircularProgress } from '@mui/material';
import { useCallback, useState } from 'react';
import '../RentalSessionPopUps.css';
import './RentalSessionToMimdinarePopUp.css';
import { useRentals } from '../../../provider/RentalSessionsWrapperProvider';
import { sendClearAllNotifications } from '../../../shared/utils/tvOverlayUtils';

function RentalSessionToMimdinarePopUp({ onOpenStateChange, onToMimdinare, session }) {
    const { rentalsById } = useRentals();
    const [isLoading, setIsLoading] = useState(false);

    const handleToMimdinare = useCallback(async () => {
        setIsLoading(true);

        if (await onToMimdinare(0, 0)) {
            sendClearAllNotifications(rentalsById[session.rentalId]?.address);
            onOpenStateChange(false);
        }

        setIsLoading(false);
    }, [onOpenStateChange, onToMimdinare, rentalsById, session.rentalId]);

    const handleCancel = useCallback(() => {
        if (!isLoading) {
            onOpenStateChange(false);
        }
    }, [isLoading, onOpenStateChange]);

    return (
        <>
            <div className="popUp-wrapper rentalCard-sessionToMimdinare">
                <div className="sessionPopUp-message">
                    Are you sure you want to change
                    <br /> "{session.rentalName}" to MIMDINARE
                </div>
                <div className="sessionPopUp-actions">
                    <button
                        className="popUp-button cancel"
                        disabled={isLoading}
                        onClick={handleCancel}>
                        Cancel
                    </button>
                    <button
                        className="popUp-button start"
                        onClick={handleToMimdinare}
                        disabled={isLoading}>
                        Confirm
                        {isLoading ? <CircularProgress className="circularProgress" /> : null}
                    </button>
                </div>
            </div>

            <Backdrop className="alert-backdrop" open={true} onClick={handleCancel} />
        </>
    );
}

export default RentalSessionToMimdinarePopUp;
