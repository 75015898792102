import { useCallback, useEffect, useMemo, useState } from 'react';
import './ExportDailyStatisticsExcel.css';
import { CircularProgress, MenuItem, Popover, TextField } from '@mui/material';
import { useHttpClient } from '../../provider/HttpClientProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import ExcelJS from 'exceljs';
import { END_DAY_EVENT_TYPE } from '../utils/constants';
import { ORGANIZATION_EVENT_END_DAY_QUERY_KEY, USER_QUERY_KEY } from '../utils/queryConstants';
import { useOrgOptions } from '../../provider/OrganizationOptionsProvider';

const emptyArray = [];
const defCellSize = 17;
const largeCellSize = 25;

function ExportDailyStatisticsExcel({ onError }) {
    const { currency } = useOrgOptions();
    const { mutateAsync: getBalanceStats, isPending: isGettingBalanceStats } =
        useGetBalanceStats(onError);
    const [anchorEl, setAnchorEl] = useState(null);
    const { data: endDays = emptyArray } = useGetEndDayEvents();
    const { data: users = emptyArray } = useGetUsers();
    const [timestamps, setTimestamps] = useState({
        start: '',
        end: '',
    });

    const handleOpen = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const handleChange = useCallback(
        (event) => {
            const changeField = event.target.name;
            const newValue = event.target.value;

            setTimestamps((curState) => {
                return { ...curState, [changeField]: newValue };
            });
        },
        [setTimestamps]
    );

    useEffect(() => {
        if (endDays.length > 0) {
            setTimestamps((curState) => {
                return { ...curState, start: endDays[0].timestamp };
            });
        }
    }, [endDays]);

    const open = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);

    const id = useMemo(() => {
        return open ? 'simple-popover' : undefined;
    }, [open]);

    const downloadGeneratedExcel = useCallback((workbook, startTimestamp, endTimestamp) => {
        workbook.xlsx.writeBuffer().then((data) => {
            const startDate = timestampToDate(startTimestamp);
            const endDate = timestampToDate(endTimestamp ? endTimestamp : Date.now());

            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');

            anchor.href = url;
            anchor.download = `${startDate}-${endDate}.xlsx`;
            anchor.click();

            window.URL.revokeObjectURL(url);
        });
    }, []);

    const exportData = useCallback(
        (data) => {
            const workbook = new ExcelJS.Workbook();
            const usernames = users.reduce((curMap, user) => {
                return { ...curMap, [user._id]: user.username };
            }, {});

            const sessionSheet = workbook.addWorksheet('Sessions');
            generateSessionSheet(sessionSheet, data, usernames, currency);

            const productsSheet = workbook.addWorksheet('Products');
            generateProductsSheet(productsSheet, data, usernames, currency);

            const cashRegistrySheet = workbook.addWorksheet('Cash Registry');
            generateCashRegistrySheet(cashRegistrySheet, data, usernames, currency);

            downloadGeneratedExcel(workbook, timestamps.start, timestamps.end);
        },
        [users, currency, downloadGeneratedExcel, timestamps.start, timestamps.end]
    );

    const generateAndExportExcel = useCallback(async () => {
        const balanceStats = await getBalanceStats({
            start: timestamps.start,
            end: timestamps.end,
        });

        if (balanceStats) {
            exportData(balanceStats);
        }
    }, [exportData, getBalanceStats, timestamps.start, timestamps.end]);

    return (
        <div className="excelDownload-wrapper">
            <button
                aria-describedby={id}
                className="export-popover-button"
                variant="contained"
                onClick={handleOpen}>
                <img src="\excelLogo.png" className="excel-download-png" alt="" />
            </button>
            <Popover
                id={id}
                className="dailyExport-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <div className="dailyExport-dataPicker-item">
                    <TextField
                        select
                        fullWidth
                        label="Start"
                        id="start"
                        value={timestamps.start}
                        size="small"
                        name="start"
                        className="dailyExport-DatePicker"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: 250,
                                    },
                                },
                            },
                        }}
                        onChange={handleChange}>
                        {endDays.map((event) => (
                            <MenuItem
                                className="dailyExport-menuItem"
                                key={event._id}
                                value={event.timestamp}>
                                {timestampToDate(event.timestamp)}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="dailyExport-dataPicker-item">
                    <TextField
                        select
                        label="End"
                        id="end"
                        value={timestamps.end}
                        size="small"
                        name="end"
                        className="dailyExport-DatePicker"
                        placeholder="Select last Date"
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        maxHeight: 250,
                                    },
                                },
                            },
                        }}
                        onChange={handleChange}>
                        <MenuItem className="dailyExport-menuItem" value="">
                            <em>select Last Date</em>
                        </MenuItem>
                        {endDays
                            .filter((event) => event.timestamp > timestamps.start)
                            .map((event) => (
                                <MenuItem
                                    className="dailyExport-menuItem"
                                    key={event._id}
                                    value={event.timestamp}>
                                    {timestampToDate(event.timestamp)}
                                </MenuItem>
                            ))}
                    </TextField>
                </div>
                <button
                    disabled={isGettingBalanceStats}
                    className="popUp-button start daily-export-button"
                    onClick={generateAndExportExcel}>
                    Export
                    {isGettingBalanceStats ? (
                        <CircularProgress size={'1rem'} color="inherit" />
                    ) : null}
                </button>
            </Popover>
        </div>
    );
}

export default ExportDailyStatisticsExcel;

function useGetEndDayEvents() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [ORGANIZATION_EVENT_END_DAY_QUERY_KEY, 30],
        queryFn: async () => {
            return await httpClient
                .get(`/organizationEvent?category=CASH_REGISTRY&type=END_DAY&limit=${30}`)
                .then((result) => {
                    if (result.status === 200) {
                        return result.data;
                    }
                });
        },
        refetchInterval: 5 * 60 * 1000,
    });
}

function useGetUsers() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [USER_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get(`/user`).then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
        refetchInterval: 5 * 60 * 1000,
    });
}

function useGetBalanceStats(setError) {
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (timestamps) => {
            return await httpClient
                .get(
                    `/stats/balance?startTimestamp=${timestamps.start}&endTimestamp=${timestamps.end}`
                )
                .then((result) => {
                    if (result.status === 200) {
                        return result.data;
                    }
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                });
        },
    });
}

const timestampToDate = (timestamp) => {
    return new Date(parseInt(timestamp)).toLocaleString();
};

const generateSessionSheet = (sessionSheet, data, usernames, currency) => {
    setSessionSheetStyles(sessionSheet, currency);

    const sessionColumns = [
        {
            name: 'Start Date',
            totalsRowLabel: 'Totals',
            filterButton: true,
        },
        {
            name: 'Expected End Date',
            totalsRowFunction: 'none',
            filterButton: true,
        },
        { name: 'Actual End Date', totalsRowFunction: 'none', filterButton: true },
        { name: 'Category', totalsRowFunction: 'none', filterButton: true },
        { name: 'Rental', totalsRowFunction: 'none', filterButton: true },
        { name: 'rentalTypes', totalsRowFunction: 'none', filterButton: true },
        { name: 'Products', totalsRowFunction: 'none', filterButton: false },
        { name: 'Cash Payment', totalsRowFunction: 'sum', filterButton: true },
        { name: 'Credit Payment', totalsRowFunction: 'sum', filterButton: true },
        { name: 'Total Price', totalsRowFunction: 'sum', filterButton: true },
    ];

    const sessionRows = data.sessionStats.map((session) => {
        return [
            timestampToDate(session.startTimestamp),
            session.endTimestamp ? timestampToDate(session.endTimestamp) : '',
            session.actualEndTimestamp ? timestampToDate(session.actualEndTimestamp) : '',
            session.rentalCategoryName,
            session.rentalName,
            session.rentalTypes
                .map((type) => {
                    return type.name;
                })
                .toString(),
            session.usedProducts
                .map((product) => {
                    return product.name + ' ' + product.quantity;
                })
                .toString(),
            parseFloat(session.paymentCash.$numberDecimal),
            parseFloat(session.paymentCredit.$numberDecimal),
            parseFloat(session.totalPrice.$numberDecimal),
        ];
    });

    sessionSheet.addTable({
        name: 'SessionTable',
        ref: 'A1',
        headerRow: true,
        totalsRow: true,
        style: {
            theme: 'TableStyleMedium19',
            showRowStripes: true,
        },
        columns: sessionColumns,
        rows: sessionRows,
    });

    sessionSheet.addTable({
        name: 'SessionProducts',
        ref: 'L1',
        headerRow: true,
        totalsRow: true,
        style: {
            theme: 'TableStyleLight10',
            showRowStripes: true,
        },
        columns: [
            {
                name: 'Product Name',
                totalsRowFunction: 'none',
                filterButton: true,
            },
            { name: 'Quantity', totalsRowFunction: 'sum', filterButton: true },
        ],
        rows: getProductsRowsForSessionSheet(data.sessionStats).sort(sortProductRowsByName),
    });
};

const setSessionSheetStyles = (sessionSheet, currency) => {
    sessionSheet.properties.defaultColWidth = defCellSize;

    sessionSheet.getColumn('A').width = largeCellSize;
    sessionSheet.getColumn('B').width = largeCellSize;
    sessionSheet.getColumn('C').width = largeCellSize;

    sessionSheet.getColumn('H').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;
    sessionSheet.getColumn('I').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;
    sessionSheet.getColumn('J').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;

    sessionSheet.getRow(1).numFmt = 'general';

    sessionSheet.getColumn('H').width = defCellSize;
    sessionSheet.getColumn('J').width = defCellSize;
    sessionSheet.getColumn('I').width = defCellSize;
};

const generateProductsSheet = (productsSheet, data, usernames, currency) => {
    setProductsSheetStyles(productsSheet, currency);

    productsSheet.addTable({
        name: 'ProductSales',
        ref: 'A1',
        headerRow: true,
        totalsRow: true,
        style: {
            theme: 'TableStyleMedium19',
            showRowStripes: true,
        },
        columns: [
            {
                name: 'Sale Date',
                totalsRowLabel: 'Totals',
                filterButton: true,
            },
            {
                name: 'Products',
                totalsRowFunction: 'none',
                filterButton: false,
            },
            { name: 'Cash Payment', totalsRowFunction: 'sum', filterButton: true },
            { name: 'Credit Payment', totalsRowFunction: 'sum', filterButton: true },
            { name: 'Total Price', totalsRowFunction: 'sum', filterButton: true },
            { name: 'User', totalsRowFunction: 'none', filterButton: true },
        ],
        rows: data.productSaleStats.map((productSale) => {
            return [
                timestampToDate(productSale.timestamp),
                productSale.products
                    .map((product) => {
                        return product.name + ' ' + product.quantity;
                    })
                    .toString(),
                parseFloat(productSale.paymentCash.$numberDecimal),
                parseFloat(productSale.paymentCredit.$numberDecimal),
                parseFloat(productSale.totalPrice.$numberDecimal),
                usernames[productSale.userId] ?? productSale.userId,
            ];
        }),
    });

    productsSheet.addTable({
        name: 'Products',
        ref: 'H1',
        headerRow: true,
        totalsRow: true,
        style: {
            theme: 'TableStyleLight10',
            showRowStripes: true,
        },
        columns: [
            {
                name: 'Product Name',
                totalsRowFunction: 'none',
                filterButton: true,
            },
            { name: 'On Product Sale', totalsRowFunction: 'sum', filterButton: true },
            { name: 'On Session Sale', totalsRowFunction: 'sum', filterButton: true },
            { name: 'Total', totalsRowFunction: 'sum', filterButton: true },
        ],
        rows: getProductsRowsForProductSheet(data).sort(sortProductRowsByName),
    });
};

const setProductsSheetStyles = (productsSheet, currency) => {
    productsSheet.properties.defaultColWidth = defCellSize;

    productsSheet.getColumn('A').width = largeCellSize;
    productsSheet.getColumn('C').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;
    productsSheet.getColumn('D').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;
    productsSheet.getColumn('E').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;

    productsSheet.getColumn('C').width = defCellSize;
    productsSheet.getColumn('D').width = defCellSize;
    productsSheet.getColumn('E').width = defCellSize;

    productsSheet.getRow(1).numFmt = 'general';
};

const generateCashRegistrySheet = (cashRegistrySheet, data, usernames, currency) => {
    setCashRegistrySheetStyles(cashRegistrySheet, currency);

    cashRegistrySheet.addTable({
        name: 'CashRegistry',
        ref: 'A1',
        headerRow: true,
        totalsRow: true,
        style: {
            theme: 'TableStyleLight10',
            showRowStripes: true,
        },
        columns: [
            {
                name: 'Event Date',
                totalsRowFunction: 'none',
                filterButton: true,
            },
            { name: 'Category', totalsRowFunction: 'none', filterButton: true },
            { name: 'Type', totalsRowFunction: 'none', filterButton: true },
            { name: 'Old Balance', totalsRowFunction: 'none', filterButton: true },
            { name: 'Withdrew Amount', totalsRowFunction: 'sum', filterButton: true },
            { name: 'New Balance', totalsRowFunction: 'none', filterButton: true },
            { name: 'User', totalsRowFunction: 'none', filterButton: true },
            { name: 'Note', totalsRowFunction: 'none', filterButton: false },
        ],
        rows: data.eventsStats.map((event) => {
            return [
                timestampToDate(event.timestamp),
                event.category,
                event.type,
                event.value.currentBalance,
                event.type === END_DAY_EVENT_TYPE
                    ? event.value.withdrewAmount * -1
                    : event.value.value,
                event.type === END_DAY_EVENT_TYPE ? event.value.value : event.value.updatedBalance,
                usernames[event.userId] ?? event.userId,
                event.message,
            ];
        }),
    });

    cashRegistrySheet.addTable({
        name: 'Incomes',
        ref: 'J1',
        headerRow: true,
        totalsRow: true,
        style: {
            theme: 'TableStyleLight10',
            showRowStripes: true,
        },
        columns: [
            {
                name: 'Income From',
                totalsRowFunction: 'none',
                filterButton: true,
            },
            {
                name: 'Cash Payment',
                totalsRowFunction: 'sum',
                filterButton: false,
            },
            { name: 'Credit Payment', totalsRowFunction: 'sum', filterButton: false },
            { name: 'Grand Total', totalsRowFunction: 'sum', filterButton: false },
        ],
        rows: [
            [
                'Sessions',
                { formula: 'SUM(SessionTable[Cash Payment])' },
                {
                    formula: 'SUM(SessionTable[Credit Payment])',
                },
                {
                    formula: 'SUM(SessionTable[Total Price])',
                },
            ],
            [
                'Products',
                { formula: 'SUM(ProductSales[Cash Payment])' },
                {
                    formula: 'SUM(ProductSales[Credit Payment])',
                },
                {
                    formula: 'SUM(ProductSales[Total Price])',
                },
            ],
        ],
    });
};

const setCashRegistrySheetStyles = (cashRegistrySheet, currency) => {
    cashRegistrySheet.properties.defaultColWidth = defCellSize;

    cashRegistrySheet.getColumn('A').width = largeCellSize;
    cashRegistrySheet.getColumn('D').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;
    cashRegistrySheet.getColumn('E').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;
    cashRegistrySheet.getColumn('F').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;

    cashRegistrySheet.getColumn('J').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;
    cashRegistrySheet.getColumn('K').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;
    cashRegistrySheet.getColumn('L').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;
    cashRegistrySheet.getColumn('M').numFmt =
        `"${currency.sign}"#,##0.00;[Red]-"${currency.sign}"#,##0.00`;

    cashRegistrySheet.getColumn('D').width = defCellSize;
    cashRegistrySheet.getColumn('E').width = defCellSize;
    cashRegistrySheet.getColumn('F').width = defCellSize;
    cashRegistrySheet.getColumn('J').width = defCellSize;
    cashRegistrySheet.getColumn('K').width = defCellSize;
    cashRegistrySheet.getColumn('L').width = defCellSize;
    cashRegistrySheet.getColumn('M').width = defCellSize;

    cashRegistrySheet.getColumn('J').alignment = { horizontal: 'center' };
    cashRegistrySheet.getColumn('K').alignment = { horizontal: 'center' };
    cashRegistrySheet.getColumn('L').alignment = { horizontal: 'center' };
    cashRegistrySheet.getColumn('M').alignment = { horizontal: 'center' };

    cashRegistrySheet.getRow(1).numFmt = 'general';
};

const getProductsRowsForSessionSheet = (sessions) => {
    const usedProducts = getProductQuantitiesFromSessions(sessions);

    const rows = [];

    for (let productName in usedProducts) {
        rows.push([productName, usedProducts[productName]]);
    }

    return rows.length ? rows : [['No Products Sold', 0]];
};

const getProductsRowsForProductSheet = (data) => {
    let usedProducts = data.productSaleStats.reduce(
        (prev, productSale) =>
            productSale.products.reduce(
                (prevProducts, product) => ({
                    ...prevProducts,
                    [product.name]: {
                        onProductSale: prev[product.name]
                            ? parseInt(prev[product.name].onProductSale) +
                              parseInt(product.quantity)
                            : parseInt(product.quantity),
                        onSessionSale: 0,
                    },
                }),
                prev
            ),
        {}
    );

    const productsSoldOnSession = getProductQuantitiesFromSessions(data.sessionStats);

    for (let productName in productsSoldOnSession) {
        usedProducts = {
            ...usedProducts,
            [productName]: {
                onSessionSale: productsSoldOnSession[productName],
                onProductSale: usedProducts[productName]?.onProductSale ?? 0,
            },
        };
    }

    for (let productName in usedProducts) {
        usedProducts[productName].total =
            usedProducts[productName].onSessionSale + usedProducts[productName].onProductSale;
    }

    const rows = [];

    for (const product in usedProducts) {
        rows.push([
            product,
            usedProducts[product].onProductSale,
            usedProducts[product].onSessionSale,
            usedProducts[product].total,
        ]);
    }

    return rows.length ? rows : [['No Products Sold', 0]];
};

const getProductQuantitiesFromSessions = (sessions) =>
    sessions.reduce((prev, session) => sumProductQuantitiesByName(session.usedProducts, prev), {});

const sumProductQuantitiesByName = (products, prev) => {
    return products.reduce(
        (prevProducts, product) => ({
            ...prevProducts,
            [product.name]: prev[product.name]
                ? parseInt(prev[product.name]) + parseInt(product.quantity)
                : parseInt(product.quantity),
        }),
        prev
    );
};

function sortProductRowsByName(a, b) {
    if (a[0] < b[0]) {
        return -1;
    } else if (a[0] > b[0]) {
        return 1;
    }

    return 0;
}
