export const USER_QUERY_KEY = 'user';

export const RENTAL_QUERY_KEY = 'rental';
export const RENTAL_CATEGORY_QUERY_KEY = 'rentalCategory';
export const PRODUCT_QUERY_KEY = 'product';
export const RENTAL_SESSION_QUERY_KEY = 'rentalSession';

export const CASH_REGISTRY_QUERY_KEY = 'organizationEvent';
export const MENU_SALE_QUERY_KEY = 'productSales';

export const PRODUCT_SALE_BALANCE_QUERY_KEY = 'productSaleBalance';
export const RENTAL_SESSION_SALE_BALANCE_QUERY_KEY = 'rentalSessionBalance';
export const CASH_REGISTRY_BALANCE_QUERY_KEY = 'cashRegistryBalance';

export const ORGANIZATION_OPTION_QUERY_KEY = 'organizationOption';
export const ORGANIZATION_EVENT_END_DAY_QUERY_KEY = 'organizationEventEndDay';

export const DASHBOARD_BAR_CHART_QUERY_KEY = 'dashboardBarChart';
export const DASHBOARD_PIE_CHART_QUERY_KEY = 'dashboardPieChart';
