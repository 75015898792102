import { useMemo, useState } from 'react';
import { useHttpClient } from '../provider/HttpClientProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TableWrapper from '../shared/TableWrapper/TableWrapper';
import MenuItem from '@mui/material/MenuItem';
import { RENTAL_QUERY_KEY } from '../shared/utils/queryConstants';
import { useOrgOptions } from '../provider/OrganizationOptionsProvider';

const emptyObject = { name: '', category: '' };

// TODO Add error handling to every request
function RentalTable() {
    const [validationErrors, setValidationErrors] = useState({});

    //call READ hook
    const { data: rentalCategories = [], isLoading } = useGetRentalCategories();
    const { overlay } = useOrgOptions();

    const useGetRentalsWithCategory = (setError) => useGetRentals(setError, rentalCategories);
    const useUpdateRentalsWithCategory = (setError) => useUpdateRental(setError, rentalCategories);
    const useCreateRentalsWithCategory = (setError) => useCreateRental(setError, rentalCategories);

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.name,
                    helperText: validationErrors?.name,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            name: undefined,
                        }),
                },
            },
            {
                header: 'Category',
                accessorKey: 'category',
                muiEditTextFieldProps: {
                    required: true,
                    error: !!validationErrors?.category,
                    helperText: validationErrors?.category,
                    onFocus: () =>
                        setValidationErrors({
                            ...validationErrors,
                            category: undefined,
                        }),

                    children: rentalCategories.map((category) => (
                        <MenuItem key={category._id} value={category.name}>
                            {category.name}
                        </MenuItem>
                    )),
                    select: true,
                },
            },
            ...(overlay?.enabled
                ? [
                      {
                          header: 'IP',
                          accessorKey: 'address',
                          muiEditTextFieldProps: {
                              required: true,
                              error: !!validationErrors?.ip,
                              helperText: validationErrors?.ip,
                              onFocus: () =>
                                  setValidationErrors({
                                      ...validationErrors,
                                      ip: undefined,
                                  }),
                          },
                      },
                  ]
                : []),
        ],
        [validationErrors, rentalCategories, overlay]
    );

    return !isLoading ? (
        <TableWrapper
            columns={columns}
            useCreate={useCreateRentalsWithCategory}
            useUpdate={useUpdateRentalsWithCategory}
            useGet={useGetRentalsWithCategory}
            useDelete={useDeleteRental}
            validate={validateRental}
            emptyObject={emptyObject}
            setValidationErrors={setValidationErrors}
            name={'Rental'}
        />
    ) : null;
}

//CREATE hook (post new rental to api)
function useCreateRental(setError, rentalCategories) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (rental) => {
            for (let category of rentalCategories) {
                if (rental.category === category.name) {
                    rental.categoryId = category._id;
                    break;
                }
            }

            return await httpClient
                .post('/rental', rental)
                .then((result) => {
                    if (result.status === 200) {
                        return true;
                    }
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        //client side optimistic update
        onMutate: (newRentalInfo) => {
            queryClient.setQueryData([RENTAL_QUERY_KEY], (prevRentals) => [
                {
                    ...newRentalInfo,
                    _id: (Math.random() + 1).toString(36).substring(7),
                },
                ...prevRentals,
            ]);
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_QUERY_KEY] }),
    });
}

//READ hook (get rentals from api)
function useGetRentals(setError, rentalCategories) {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [RENTAL_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/rental').then((result) => {
                if (result.status === 200) {
                    for (let rental of result.data) {
                        for (let category of rentalCategories) {
                            if (rental.categoryId === category._id) {
                                rental.category = category.name;
                                break;
                            }
                        }
                    }

                    return result.data.reverse();
                }
            });
        },
    });
}

function useGetRentalCategories() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: ['rentalCategory'],
        queryFn: async () => {
            return await httpClient.get('/rentalCategory').then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
        refetchInterval: 60 * 1000,
    });
}

//UPDATE hook (put rental in api)
function useUpdateRental(setError, rentalCategories) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (rental) => {
            for (let category of rentalCategories) {
                if (rental.category === category.name) {
                    rental.categoryId = category._id;
                    break;
                }
            }

            return await httpClient
                .put('/rental', rental)
                .then((result) => {
                    return true;
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        //client side optimistic update
        onMutate: (newRentalInfo) => {
            queryClient.setQueryData([RENTAL_QUERY_KEY], (prevRentals) =>
                prevRentals?.map((prevRental) =>
                    prevRental._id === newRentalInfo._id
                        ? {
                              ...newRentalInfo,
                          }
                        : prevRental
                )
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_QUERY_KEY] }),
    });
}

//DELETE hook (delete rental in api)
function useDeleteRental() {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (rentalId) => {
            return await httpClient
                .delete('/rental', { data: { _id: rentalId } })
                .then((result) => {
                    return result;
                });
        },
        //client side optimistic update
        onMutate: (rentalId) => {
            queryClient.setQueryData([RENTAL_QUERY_KEY], (prevRentals) =>
                prevRentals?.filter((rental) => rental._id !== rentalId)
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_QUERY_KEY] }),
    });
}

const validateRequired = (value) => !!value.length;

function validateRental(rental) {
    return {
        name: !validateRequired(rental.name) ? 'Name is required' : '',
        category: !validateRequired(rental.category) ? 'Category is required' : '',
    };
}

export default RentalTable;
