import { useEffect, useMemo, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import './Timers.css';
import {
    calculateMsDurationInCategoryFromTransferHistory,
    calculateOffset,
    calculatePrice,
    formatTime,
    msToSeconds,
} from '../rentalSessionUtils';
import { useOrgOptions } from '../../provider/OrganizationOptionsProvider';
import { useRentalCategories } from '../../provider/RentalSessionsWrapperProvider';
import { sendClearAllNotifications } from '../../shared/utils/tvOverlayUtils';

function MimdinareTimer({
    startTimestamp,
    rentalTypes,
    setTotalPrice,
    totalPrice,
    isPaused,
    transferHistory,
    categoryId,
    rentalAddress,
}) {
    const previousDurationMSInCategory = useMemo(
        () => calculateMsDurationInCategoryFromTransferHistory(transferHistory, categoryId),
        [categoryId, transferHistory]
    );
    const startTimestampInCategory = useMemo(
        () => parseInt(startTimestamp) - previousDurationMSInCategory,
        [previousDurationMSInCategory, startTimestamp]
    );
    const [offsetTimestamp, setOffsetTimestamp] = useState(
        calculateOffset(startTimestampInCategory)
    );

    const { totalSeconds, seconds, minutes, hours, isRunning, reset } = useStopwatch({
        autoStart: true,
        offsetTimestamp,
    });
    const { currency } = useOrgOptions();
    const { categoriesMap } = useRentalCategories();

    useEffect(() => {
        setOffsetTimestamp(calculateOffset(startTimestampInCategory));
        reset(calculateOffset(startTimestampInCategory), !isPaused);
    }, [isPaused, reset, startTimestampInCategory]);

    useEffect(() => {
        setTotalPrice(
            calculatePrice(
                (totalSeconds - msToSeconds(previousDurationMSInCategory)) / 60,
                categoryId,
                rentalTypes,
                transferHistory,
                categoriesMap
            )
        );

        if (totalSeconds % 60 === 0) {
            sendClearAllNotifications(rentalAddress);
        }
    }, [
        totalSeconds,
        rentalTypes,
        setTotalPrice,
        categoryId,
        transferHistory,
        categoriesMap,
        rentalAddress,
        previousDurationMSInCategory,
    ]);

    return (
        <>
            <div>
                <div
                    style={{ fontSize: '42px' }}
                    className={'mimdinare ' + (isRunning ? 'live' : 'offline')}>
                    <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:
                    <span>{formatTime(seconds)}</span>
                </div>
                <div style={{ height: 'fitContent' }}>
                    <label className="liveSession-price-label" htmlFor="price">
                        Price:
                    </label>
                    <input
                        id="price"
                        className="liveSession-price"
                        value={totalPrice + currency.sign}
                        disabled
                    />
                </div>
            </div>
        </>
    );
}

export default MimdinareTimer;
