import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useHttpClient } from '../../provider/HttpClientProvider';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { MENU_SALE_QUERY_KEY } from '../utils/queryConstants';
import Alert from '../Alert/Alert';
import { convertDateRangeToTimestamp } from '../utils/timestampUtils';
import { useGetUsers } from '../../provider/RentalSessionsWrapperProvider';

const emptyObj = {
    docs: [],
};

const emptyArray = [];

function MenuSaleHistoryTable() {
    const { data: users = emptyArray } = useGetUsers();
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [dateRange, setDateRange] = useState({
        startDate: dayjs(Date.now()),
        endDate: dayjs(Date.now()),
    });
    const [error, setError] = useState();

    const {
        data = emptyObj,
        isError: isMenuSaleError,
        isFetching: isMenuSaleFetching,
        isLoading: isMenuSaleLoading,
        isPreviousData,
    } = useGetMenuSale(pagination.pageIndex, pagination.pageSize, fetchData, dateRange);

    useEffect(() => {
        if (!isPreviousData && data?.hasNextPage) {
            queryClient.prefetchQuery({
                queryKey: [
                    MENU_SALE_QUERY_KEY,
                    pagination.pageIndex + 1,
                    pagination.pageSize,
                    dateRange,
                ],
                queryFn: () =>
                    fetchData(httpClient, pagination.pageIndex + 1, pagination.pageSize, dateRange),
            });
        }
    }, [
        data,
        isPreviousData,
        dateRange,
        pagination.pageSize,
        pagination.pageIndex,
        queryClient,
        httpClient,
    ]);

    const handleDatePick = useCallback(
        (value, targetedDate) => {
            setDateRange((curDateRange) => ({ ...curDateRange, [targetedDate]: value }));
        },
        [setDateRange]
    );

    const usernamesMap = useMemo(
        () =>
            users.reduce((curMap, user) => {
                return { ...curMap, [user._id]: user.username };
            }, {}),
        [users]
    );

    const columns = useMemo(
        () => [
            {
                header: 'Date',
                accessorFn: (dataRow) => {
                    if (dataRow.products) {
                        return new Date(parseInt(dataRow.timestamp)).toLocaleString('it-IT');
                    }
                },
            },
            {
                header: 'Username',
                accessorFn: (dataRow) => {
                    if (dataRow.products) {
                        return usernamesMap[dataRow.userId] ?? dataRow.userId;
                    }
                },
            },
            {
                header: 'Product Names',
                accessorFn: (dataRow) => {
                    if (dataRow.products) {
                        return dataRow.products
                            .map((product) => ' ' + product.name + ' - ' + product.quantity)
                            .toString();
                    }
                    return <i>{dataRow.name}</i>;
                },
            },
            {
                header: 'Quantity',
                accessorFn: (dataRow) => {
                    if (dataRow.products) {
                        return dataRow.products.reduce(
                            (prevSum, product) => prevSum + product.quantity,
                            0
                        );
                    }

                    return <i>{dataRow.quantity}</i>;
                },
            },
            {
                header: 'Total Price',
                accessorFn: (dataRow) => {
                    if (dataRow.totalPrice) {
                        return dataRow.totalPrice.$numberDecimal;
                    }

                    return <i>{(dataRow.price.$numberDecimal * dataRow.quantity).toFixed(2)}</i>;
                },
            },
            {
                header: 'Payment Cash',
                accessorFn: (dataRow) => {
                    if (dataRow.totalPrice) {
                        return dataRow.paymentCash.$numberDecimal;
                    }

                    return;
                },
            },
            {
                header: 'Payment Credit',
                accessorFn: (dataRow) => {
                    if (dataRow.totalPrice) {
                        return dataRow.paymentCredit.$numberDecimal;
                    }

                    return;
                },
            },
        ],
        [usernamesMap]
    );

    const table = useMaterialReactTable({
        columns,

        data: data.docs,

        getSubRows: (row) =>
            row.products?.map((product) => {
                return { ...product, _id: product._id + row._id };
            }),

        enableExpanding: true,

        enableColumnOrdering: false,

        enableDensityToggle: false,

        enableColumnFilters: false,

        enableSorting: false,

        enableColumnFilterModes: false,

        enableHiding: false,

        enableColumnActions: false,

        enablePagination: true,

        manualPagination: true,

        enableToolbarInternalActions: false,

        rowCount: data.totalDocs,

        positionPagination: 'both',

        enableBottomToolbar: true,

        positionToolbarDropZone: 'none',

        positionActionsColumn: 'last',

        layoutMode: 'grid',

        getRowId: (row) => row._id,

        muiTableHeadCellProps: {},

        muiTableBodyRowProps: {
            hover: false,
        },

        muiTableContainerProps: {
            sx: {
                overflowY: 'scroll',
                flex: 1,
            },
        },

        muiSearchTextFieldProps: {
            sx: {
                width: '10rem',
            },
        },

        muiTopToolbarProps: {
            sx: {
                '& > div:nth-of-type(2)': {
                    width: '100%',
                    flex: '1',
                },
                '&': {
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0.2rem 0',
                },
            },
        },

        muiPaginationProps: {
            sx: {
                margin: '0',
            },
        },

        onPaginationChange: setPagination,

        renderTopToolbarCustomActions: () => (
            <div className="topTolbarCustomActions-wrapper">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                            name="startDate"
                            label="Start Date"
                            value={dateRange.startDate}
                            onChange={(newValue) => handleDatePick(newValue, 'startDate')}
                            className="datePicker"
                        />

                        <DatePicker
                            name="startDate"
                            label="End Date"
                            value={dateRange.endDate}
                            onChange={(newValue) => handleDatePick(newValue, 'endDate')}
                            className="datePicker"
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
        ),

        state: {
            isLoading: isMenuSaleLoading,
            showAlertBanner: isMenuSaleError,
            showProgressBars: isMenuSaleFetching,
            pagination,
        },

        initialState: {
            expanded: false,
        },
    });

    return (
        <>
            <MaterialReactTable table={table} />
            <Alert message={error} setMessage={setError} />
        </>
    );
}

export default MenuSaleHistoryTable;

const fetchData = async (httpClient, pageIndex, pageSize, dateRange) => {
    return await httpClient
        .get(
            `/productSales/history?page=` +
                (pageIndex + 1) +
                '&pageSize=' +
                pageSize +
                (dateRange.startDate
                    ? '&startDate=' + convertDateRangeToTimestamp(dateRange.startDate.$d)
                    : '') +
                (dateRange.endDate
                    ? '&endDate=' + convertDateRangeToTimestamp(dateRange.endDate.$d)
                    : '')
        )
        .then((result) => {
            if (result.status === 200) {
                return result.data;
            }
        });
};

function useGetMenuSale(pageIndex, pageSize, fetchData, dateRange) {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [MENU_SALE_QUERY_KEY, pageIndex, pageSize, dateRange],
        queryFn: () => fetchData(httpClient, pageIndex, pageSize, dateRange),
        keepPreviousData: true,
        staleTime: 5000,
    });
}
