import { useCallback, useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { FLASHING_INTERVAL } from './TimerConstants';
import { calculatePrice, formatTime } from '../rentalSessionUtils';
import { useOrgOptions } from '../../provider/OrganizationOptionsProvider';
import { useRentalCategories } from '../../provider/RentalSessionsWrapperProvider';
import {
    sendClearAllNotifications,
    sendTimerWarningNotification,
} from '../../shared/utils/tvOverlayUtils';

function FixedTimer({
    originalEndTimestamp,
    startTimestamp,
    totalPrice,
    setTotalPrice,
    isPaused,
    endTimestamp,
    setIsExpired,
    isExpired,
    rentalTypes,
    transferHistory,
    categoryId,
    rentalAddress,
}) {
    const [isFlashing, setIsFlashing] = useState(false);
    const { seconds, minutes, hours, isRunning, restart } = useTimer({
        endTimestamp,
        onExpire: () => handleExpire(),
        autoStart: true,
    });
    const { currency } = useOrgOptions();
    const orgOptions = useOrgOptions();
    const { categoriesMap } = useRentalCategories();

    useEffect(() => {
        setTotalPrice(
            calculatePrice(
                (originalEndTimestamp - startTimestamp) / (1000 * 60),
                categoryId,
                rentalTypes,
                transferHistory,
                categoriesMap
            )
        );
    }, [
        originalEndTimestamp,
        startTimestamp,
        rentalTypes,
        setTotalPrice,
        categoryId,
        transferHistory,
        categoriesMap,
    ]);

    useEffect(() => {
        if (seconds === 0 && !isExpired) {
            sendClearAllNotifications(rentalAddress);
        }

        if (seconds === 0 && (minutes === 10 || minutes === 5) && hours === 0) {
            sendTimerWarningNotification(rentalAddress, minutes, orgOptions.overlay?.languages);
        }
    }, [seconds, minutes, hours, isExpired, rentalAddress, orgOptions.overlay?.languages]);

    useEffect(() => {
        restart(endTimestamp, !isPaused);
        setIsExpired(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaused, endTimestamp]);

    const handleExpire = useCallback(() => {
        setIsExpired(true);
    }, [setIsExpired]);

    useEffect(() => {
        if (isExpired) {
            const interval = setInterval(() => {
                if (isExpired) {
                    setIsFlashing((curState) => !curState);
                }
            }, FLASHING_INTERVAL);
            return () => clearInterval(interval);
        }
    }, [isExpired]);

    return (
        <>
            <div className="timerWrapper">
                <div
                    style={{ fontSize: '42px' }}
                    className={
                        'fixed ' +
                        (isRunning ? 'live' : '') +
                        (!isRunning && !isExpired ? ' paused' : '') +
                        (isFlashing && isExpired ? ' expired' : '')
                    }>
                    <span>{formatTime(hours)}</span>:<span>{formatTime(minutes)}</span>:
                    <span>{formatTime(seconds)}</span>
                </div>
                <div style={{ height: 'fitContent' }}>
                    <label className="liveSession-price-label" htmlFor="price">
                        Price:
                    </label>
                    <input
                        id="price"
                        className="liveSession-price"
                        value={totalPrice + currency.sign}
                        disabled
                    />
                </div>
            </div>
        </>
    );
}

export default FixedTimer;
