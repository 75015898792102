import { useCallback, useEffect, useMemo, useState } from 'react';
import TimeInput from '../../../shared/TimeInput/TimeInput';
import Select from 'react-select';
import './RentalSessionTransferPopUp.css';
import '../RentalSessionPopUps.css';
import { Backdrop, CircularProgress } from '@mui/material';
import {
    calculatePrice,
    calculateSessionMSDuration,
    msToMinutes,
    roundValue,
} from '../../rentalSessionUtils';
import PaymentMethodInputs from '../../../shared/PaymentMethodInputs/PaymentMethodInputs';
import { useOrgOptions } from '../../../provider/OrganizationOptionsProvider';
import { useRentalCategories, useRentals } from '../../../provider/RentalSessionsWrapperProvider';
import {
    CONTINUES_TRANSFER_TYPE,
    FIXED_TRANSFER_TYPE,
    KEEP_SAME_TRANSFER_TYPE,
} from '../../../shared/utils/constants';
import { sendClearAllNotifications } from '../../../shared/utils/tvOverlayUtils';

const transferFormInitialState = {
    category: undefined,
    transferType: undefined,
    rental: undefined,
    rentalTypes: undefined,
    duration: undefined,
};

function RentalSessionTransferPopUp({ onOpenStateChange, onTransfer, session }) {
    const [payments, setPayments] = useState({
        cash: 0,
        credit: 0,
    });
    const [transferForm, setTransferForm] = useState({ ...transferFormInitialState });
    const [isLoading, setIsLoading] = useState(false);
    const { currency } = useOrgOptions();
    const { categoriesMap, categories } = useRentalCategories();
    const { rentalsMapByCategory } = useRentals();
    const orgOptions = useOrgOptions();
    const { rentalsById } = useRentals();

    const prepaidBalance = useMemo(() => {
        const totalPrepaidCash = parseFloat(session.paymentCash.$numberDecimal);
        const totalPrepaidCredit = parseFloat(session.paymentCredit.$numberDecimal);
        const totalPrepaid = totalPrepaidCash + totalPrepaidCredit;

        return {
            totalPrepaid,
            totalPrepaidCash,
            totalPrepaidCredit,
        };
    }, [session]);

    const totalPrice = useMemo(() => {
        const menuPrice = session.usedProducts.reduce(
            (partialSum, product) =>
                (
                    parseFloat(partialSum) +
                    product.quantity * parseFloat(product.price.$numberDecimal)
                ).toFixed(2),
            0
        );

        const timerPrice = calculatePrice(
            msToMinutes(
                calculateSessionMSDuration(session.startTimestamp, Date.now(), session.pauses)
            ),
            session.rentalCategoryId,
            session.rentalTypes,
            session.transferHistory,
            categoriesMap
        );

        return (roundValue(timerPrice, orgOptions) + parseFloat(menuPrice)).toFixed(2);
    }, [
        session.usedProducts,
        session.startTimestamp,
        session.pauses,
        session.rentalCategoryId,
        session.rentalTypes,
        session.transferHistory,
        categoriesMap,
        orgOptions,
    ]);

    const additionalPrice = useMemo(() => {
        const calculatePriceDifference = () => {
            if (
                !transferForm.category ||
                !transferForm.transferType ||
                transferForm.transferType?.value !== FIXED_TRANSFER_TYPE ||
                !transferForm.duration
            ) {
                return 0;
            }

            const sessionMinuteDuration = msToMinutes(
                calculateSessionMSDuration(session.startTimestamp, Date.now(), session.pauses)
            );

            const originalPrice = parseFloat(
                calculatePrice(
                    sessionMinuteDuration,
                    session.rentalCategoryId,
                    session.rentalTypes,
                    session.transferHistory,
                    categoriesMap
                )
            );

            const newTransferHistory = [
                ...session.transferHistory,
                {
                    startTimestamp: session.startTimestamp,
                    endTimestamp: Date.now(),
                    rentalId: session.rentalId,
                    rentalCategoryId: session.rentalCategoryId,
                    rentalTypes: session.rentalTypes,
                    pauses: session.pauses,
                },
            ];
            const newPrice = parseFloat(
                calculatePrice(
                    transferForm.duration,
                    transferForm.category.value,
                    categoriesMap[transferForm.category.value].rentalTypes.filter(
                        (rentalType) => transferForm.rentalTypes?.value === rentalType._id
                    ),
                    newTransferHistory,
                    categoriesMap
                )
            );

            return newPrice - originalPrice;
        };

        return calculatePriceDifference().toFixed(2);
    }, [categoriesMap, session, transferForm]);

    useEffect(() => {
        const handleTransferFormChange = (state) => {
            const formKeys = Object.keys(state);
            let updatedState = undefined;

            let nullFound;
            for (let key of formKeys) {
                if (!state[key]) {
                    if (key === 'rentalTypes') {
                        continue;
                    }

                    nullFound = true;
                } else if (nullFound) {
                    updatedState = { ...updatedState, [key]: undefined };
                }
            }

            if (
                state.category?.value &&
                state.transferType?.value &&
                state.transferType?.value === KEEP_SAME_TRANSFER_TYPE &&
                state.category?.value !== session.rentalCategoryId
            ) {
                updatedState = { ...updatedState, transferType: undefined };
            }

            if (
                state.category?.value &&
                state.rental?.categoryId &&
                state.category?.value !== state.rental?.categoryId
            ) {
                updatedState = { ...updatedState, rental: undefined };
                updatedState = { ...updatedState, rentalTypes: undefined };
            }

            if (
                state.transferType?.value &&
                state.transferType?.value === KEEP_SAME_TRANSFER_TYPE &&
                state.rentalTypes?.value
            ) {
                updatedState = { ...updatedState, rentalTypes: undefined };
            }

            return updatedState ? { ...state, ...updatedState } : state;
        };

        setTransferForm(handleTransferFormChange);
    }, [session.rentalCategoryId, transferForm]);

    const allowTransfer = useMemo(() => {
        if (!transferForm.rental?.value) {
            return false;
        }

        if (
            transferForm.transferType?.value &&
            transferForm.transferType?.value !== FIXED_TRANSFER_TYPE
        ) {
            return true;
        }

        return !!transferForm.duration;
    }, [transferForm.duration, transferForm.rental, transferForm.transferType]);

    const handlePaymentsInput = useCallback(
        (paymentInputs) => {
            setPayments({ ...paymentInputs });
        },
        [setPayments]
    );

    const handleCancel = useCallback(() => {
        if (!isLoading) {
            onOpenStateChange(false);
        }
    }, [onOpenStateChange, isLoading]);

    const handleTransfer = useCallback(async () => {
        setIsLoading(true);
        if (
            await onTransfer(
                transferForm.transferType?.value,
                transferForm.rental?.value,
                transferForm.duration,
                [transferForm.rentalTypes?.value],
                payments.cash,
                payments.credit
            )
        ) {
            sendClearAllNotifications(rentalsById[session.rentalId]?.address);
            sendClearAllNotifications(rentalsById[transferForm.rental?.value]?.address);

            onOpenStateChange(false);
        }
        setIsLoading(false);
    }, [
        onOpenStateChange,
        onTransfer,
        payments.cash,
        payments.credit,
        rentalsById,
        session.rentalId,
        transferForm,
    ]);

    const handleSelectChange = useCallback(
        (event, action) => {
            setTransferForm((curState) => ({ ...curState, [action.name]: event }));
        },
        [setTransferForm]
    );

    const handleDurationChange = useCallback((duration) => {
        setTransferForm((curState) => ({ ...curState, duration }));
    }, []);

    const categorySelectOptions = useMemo(() => {
        return categories.map((category) => ({
            value: category._id,
            label: category.name,
        }));
    }, [categories]);

    const transferTypeSelectOptions = useMemo(() => {
        const defaultOptions = [
            { value: FIXED_TRANSFER_TYPE, label: 'FIXED' },
            { value: CONTINUES_TRANSFER_TYPE, label: 'MIMDINARE' },
        ];

        if (transferForm.category?.value === session.rentalCategoryId) {
            defaultOptions.push({ value: KEEP_SAME_TRANSFER_TYPE, label: 'KEEP SAME' });
        }

        return defaultOptions;
    }, [session.rentalCategoryId, transferForm.category]);

    const rentalSelectOptions = useMemo(() => {
        return rentalsMapByCategory[transferForm.category?.value]?.map((rental) => ({
            value: rental._id,
            label: rental.name,
            categoryId: rental.categoryId,
        }));
    }, [rentalsMapByCategory, transferForm.category]);

    const rentalTypesSelectOptions = useMemo(() => {
        return categoriesMap[transferForm.category?.value]?.rentalTypes.map((rentalType) => ({
            value: rentalType._id,
            label: rentalType.name,
        }));
    }, [categoriesMap, transferForm.category]);

    const customStyles = useMemo(
        () => ({
            option: (base, { isFocused }) => {
                return {
                    ...base,
                    backgroundColor: isFocused ? 'var(--primary-color)' : 'var(--secondary-blue)',
                };
            },
            menu: (base) => {
                return {
                    ...base,
                    backgroundColor: 'var(--secondary-blue)',
                };
            },
            control: (base) => {
                return {
                    ...base,
                    backgroundColor: 'transparent',
                    width: '100%',
                    border: 'none',
                    boxShadow: 'none',
                };
            },
            container: (base) => {
                return {
                    ...base,
                    borderColor: 'transparent',
                    borderBottom: '1px solid rgb(160, 149, 149)',
                };
            },
        }),
        []
    );

    return (
        <>
            <div className="popUp-wrapper rentalSession-transfer">
                <div className="transfer-prices">
                    <div>
                        <label htmlFor="curPrice">Expected Price:</label>
                        <input
                            type="text"
                            id="curPrice"
                            value={parseFloat(totalPrice).toFixed(2) + currency.sign}
                            disabled
                            className="priceInputs"
                        />
                    </div>
                    <div>
                        <label htmlFor="curPrice">Prepaid Balance:</label>
                        <input
                            type="text"
                            id="curPrice"
                            value={prepaidBalance.totalPrepaid.toFixed(2) + currency.sign}
                            disabled
                            className="priceInputs"
                        />
                    </div>
                    <div className="transfer-item">
                        <label htmlFor="rentalType">Category:</label>
                        <div className="transfer-inputClass">
                            <Select
                                options={categorySelectOptions}
                                isClearable
                                placeholder="Category..."
                                name="category"
                                id="category"
                                onChange={handleSelectChange}
                                classNamePrefix="react-select"
                                className="inputWindows"
                                styles={customStyles}
                            />
                        </div>
                    </div>
                    <div className="transfer-item">
                        <label htmlFor="rentalType">Transfer Type:</label>
                        <div className="transfer-inputClass">
                            <Select
                                options={transferTypeSelectOptions}
                                isClearable
                                placeholder="Transfer Type..."
                                name="transferType"
                                id="transferType"
                                onChange={handleSelectChange}
                                classNamePrefix="react-select"
                                className="inputWindows"
                                styles={customStyles}
                                value={transferForm.transferType ?? ''}
                                isDisabled={!transferForm.category}
                            />
                        </div>
                    </div>
                    <div className="transfer-item">
                        <label htmlFor="rentalType">Rental:</label>
                        <div className="transfer-inputClass">
                            <Select
                                options={rentalSelectOptions}
                                isClearable
                                placeholder="Rental..."
                                name="rental"
                                id="rental"
                                onChange={handleSelectChange}
                                classNamePrefix="react-select"
                                className="inputWindows"
                                styles={customStyles}
                                value={transferForm.rental ?? ''}
                                isDisabled={!transferForm.transferType}
                            />
                        </div>
                    </div>
                    <div className="transfer-item">
                        <label htmlFor="rentalType">Rental Type:</label>
                        <div className="transfer-inputClass">
                            <Select
                                options={rentalTypesSelectOptions}
                                isClearable
                                placeholder="Rental Type..."
                                name="rentalTypes"
                                id="rentalTypes"
                                onChange={handleSelectChange}
                                classNamePrefix="react-select"
                                className="inputWindows"
                                styles={customStyles}
                                value={transferForm.rentalTypes ?? ''}
                                isDisabled={
                                    !transferForm.rental ||
                                    transferForm.transferType?.value === KEEP_SAME_TRANSFER_TYPE
                                }
                            />
                        </div>
                    </div>
                    <div className="additionalPrice-timeInput-container">
                        <label htmlFor="timeInput">Time:</label>
                        <TimeInput
                            onChange={handleDurationChange}
                            disabled={
                                !transferForm.rental ||
                                transferForm?.transferType?.value !== FIXED_TRANSFER_TYPE
                            }
                        />
                    </div>
                    <div>
                        <label htmlFor="additionalPrice">Additional Price:</label>
                        <input
                            type="text"
                            id="additionalPrice"
                            value={additionalPrice + currency.sign}
                            disabled
                            className="priceInputs"
                        />
                    </div>
                    <div>
                        <PaymentMethodInputs
                            maxTotalValue={
                                parseFloat(totalPrice) -
                                prepaidBalance.totalPrepaid +
                                parseFloat(additionalPrice)
                            }
                            onInput={handlePaymentsInput}
                            strictMode={false}
                        />
                    </div>
                </div>
                <div className="transfer-footer">
                    <div className="transfer-totalPrice-container">
                        <label htmlFor="futurePrice" className="transfer-totalPriceLabel">
                            Total Price:
                        </label>
                        <input
                            type="text"
                            className="priceInputs"
                            id="futurePrice"
                            value={
                                (
                                    parseFloat(totalPrice) +
                                    parseFloat(additionalPrice) -
                                    parseFloat(prepaidBalance.totalPrepaid) -
                                    payments.cash -
                                    payments.credit
                                ).toFixed(2) + currency.sign
                            }
                            disabled
                        />
                    </div>

                    <div className="sessionPopUp-actions transfer-actions">
                        <button
                            onClick={handleCancel}
                            disabled={isLoading}
                            className="popUp-button cancel">
                            Cancel
                        </button>
                        <button
                            onClick={handleTransfer}
                            disabled={isLoading || !allowTransfer}
                            className="popUp-button start">
                            Transfer
                            {isLoading ? <CircularProgress size={'1rem'} color="inherit" /> : null}
                        </button>
                    </div>
                </div>
            </div>
            <Backdrop className="alert-backdrop" open={true} onClick={handleCancel} />
        </>
    );
}

export default RentalSessionTransferPopUp;
