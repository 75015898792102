import { createContext, useContext, useMemo } from 'react';
import { useHttpClient } from './HttpClientProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    PRODUCT_QUERY_KEY,
    PRODUCT_SALE_BALANCE_QUERY_KEY,
    RENTAL_CATEGORY_QUERY_KEY,
    RENTAL_QUERY_KEY,
    RENTAL_SESSION_QUERY_KEY,
    RENTAL_SESSION_SALE_BALANCE_QUERY_KEY,
    USER_QUERY_KEY,
} from '../shared/utils/queryConstants';
import { isFixedType } from '../RentalSessions/rentalSessionUtils';

const emptyResult = [];

const RentalCategoryContext = createContext();
const RentalContext = createContext();
const RentalSessionContext = createContext();

const RentalSessionsWrapperProvider = ({ children }) => {
    const {
        data: rentalCategories = emptyResult,
        isError: isRentalCategoriesError,
        isFetching: isRentalCategoriesFetching,
        isLoading: isRentalCategoriesLoading,
    } = useGetRentalCategories();

    const {
        data: rentals = emptyResult,
        isError: isRentalsError,
        isFetching: isRentalsFetching,
        isLoading: isRentalsLoading,
    } = useGetRentals();

    const {
        data: rentalSessions = emptyResult,
        isError: isRentalSessionsError,
        isFetching: isRentalSessionsFetching,
        isLoading: isRentalSessionsLoading,
    } = useGetRentalSessions();

    const rentalCategoryContext = useMemo(
        () => ({
            categories: rentalCategories,
            categoriesMap: rentalCategories.reduce(
                (curMap, category) => ({ ...curMap, [category._id]: category }),
                {}
            ),
            isRentalCategoriesError,
            isRentalCategoriesFetching,
            isRentalCategoriesLoading,
        }),
        [
            rentalCategories,
            isRentalCategoriesError,
            isRentalCategoriesFetching,
            isRentalCategoriesLoading,
        ]
    );

    const rentalContext = useMemo(
        () => ({
            rentals,
            rentalsMapByCategory: rentals.reduce(
                (curMap, rental) => ({
                    ...curMap,
                    [rental.categoryId]: [...[...(curMap[rental.categoryId] ?? [])], rental],
                }),
                {}
            ),
            rentalsById: rentals.reduce(
                (curMap, rental) => ({
                    ...curMap,
                    [rental._id]: rental,
                }),
                {}
            ),

            isRentalsError,
            isRentalsFetching,
            isRentalsLoading,
        }),
        [rentals, isRentalsError, isRentalsFetching, isRentalsLoading]
    );

    const rentalSessionContext = useMemo(
        () => ({
            rentalSessions,
            rentalSessionsByCategory: rentalSessions.reduce(
                (prev, session) => ({
                    ...prev,
                    [session.rentalCategoryId]: {
                        ...prev[session.rentalCategoryId],
                        [session.rentalId]: session,
                    },
                }),
                {}
            ),

            isRentalSessionsError,
            isRentalSessionsFetching,
            isRentalSessionsLoading,
        }),
        [rentalSessions, isRentalSessionsError, isRentalSessionsFetching, isRentalSessionsLoading]
    );

    return (
        <RentalCategoryContext.Provider value={rentalCategoryContext}>
            <RentalContext.Provider value={rentalContext}>
                <RentalSessionContext.Provider value={rentalSessionContext}>
                    {children}
                </RentalSessionContext.Provider>
            </RentalContext.Provider>
        </RentalCategoryContext.Provider>
    );
};

export const useRentalCategories = () => {
    return useContext(RentalCategoryContext);
};

export const useRentals = () => {
    return useContext(RentalContext);
};

export const useRentalSessions = () => {
    return useContext(RentalSessionContext);
};

export default RentalSessionsWrapperProvider;

export function useGetRentalCategories() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [RENTAL_CATEGORY_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/rentalCategory').then((result) => {
                if (result.status === 200) {
                    return result.data.reverse();
                }
            });
        },
        refetchInterval: (5 * 60 + 3) * 1000,
    });
}

export function useGetUsers() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [USER_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get(`/user`).then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
        refetchInterval: 5 * 60 * 1000,
    });
}

export function useGetRentals() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [RENTAL_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get(`/rental`).then((result) => {
                if (result.status === 200) {
                    return result.data.reverse();
                }
            });
        },
        refetchInterval: (5 * 60 + 1) * 1000,
    });
}

export function useGetRentalSessions() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [RENTAL_SESSION_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get(`/rentalSession`).then((result) => {
                if (result.status === 200) {
                    return result.data;
                }
            });
        },
    });
}

export function useStartRentalSession(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (rentalSession) => {
            return await httpClient
                .post('/rentalSession', rentalSession)
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_SALE_BALANCE_QUERY_KEY] });
        },
    });
}

export function useEndRentalSession(onError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (rentalSession) => {
            return await httpClient
                .post('/rentalSession/end', rentalSession)
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    onError(err.response.data.error.message);
                    return false;
                });
        },

        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_SALE_BALANCE_QUERY_KEY] });
        },
    });
}

export function useUpdateRentalSession(onError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (editedRentalSessionInfo) => {
            return await httpClient
                .put('/rentalSession', {
                    sessionId: editedRentalSessionInfo.sessionId,
                    startTimestamp: editedRentalSessionInfo.startTimestamp,
                    rentalTypeIds: editedRentalSessionInfo.rentalTypeIds[0]
                        ? [editedRentalSessionInfo.rentalTypeIds[0]._id]
                        : [],
                    message: editedRentalSessionInfo.message,
                })
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    onError(err.response.data.error.message);
                    return false;
                });
        },
        // client side optimistic update
        onMutate: (newRentalSessionInfo) => {
            queryClient.setQueryData([RENTAL_SESSION_QUERY_KEY], (prevRentalSessions) =>
                prevRentalSessions?.map((prevRentalSession, index) =>
                    prevRentalSession._id === newRentalSessionInfo.sessionId
                        ? {
                              ...prevRentalSessions[index],
                              endTimestamp: isFixedType(prevRentalSessions[index].priceType)
                                  ? parseInt(prevRentalSessions[index].endTimestamp) -
                                    (parseInt(prevRentalSessions[index].startTimestamp) -
                                        parseInt(newRentalSessionInfo.startTimestamp))
                                  : parseInt(prevRentalSessions[index].endTimestamp),
                          }
                        : prevRentalSession
                )
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] }),
    });
}

export function useTransferRentalSession(onError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (transferredRentalSessionInfo) => {
            return await httpClient
                .post('/rentalSession/transfer', {
                    sessionId: transferredRentalSessionInfo.sessionId,
                    transferType: transferredRentalSessionInfo.transferType,
                    rentalId: transferredRentalSessionInfo.rentalId,
                    startTimestamp: transferredRentalSessionInfo.startTimestamp,
                    endTimestamp: transferredRentalSessionInfo.endTimestamp,
                    rentalTypeIds: transferredRentalSessionInfo.rentalTypeIds,
                    prePaidCash: transferredRentalSessionInfo.prePaidCash,
                    prePaidCredit: transferredRentalSessionInfo.prePaidCredit,
                })
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    onError(err.response.data.error.message);
                    return false;
                });
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] }),
    });
}

export function usePauseRentalSession(onError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (pause) => {
            return await httpClient
                .post('/rentalSession/pause', {
                    sessionId: pause.sessionId,
                    timestamp: pause.timestamp,
                })
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    onError(err.response.data.error.message);
                    return false;
                });
        },
        // client side optimistic update
        onMutate: (rentalSession) => {
            queryClient.setQueryData([RENTAL_SESSION_QUERY_KEY], (prevRentalSessions) =>
                prevRentalSessions?.map((prevRentalSession, index) =>
                    prevRentalSession._id === rentalSession.sessionId
                        ? {
                              ...prevRentalSessions[index],
                              paused: true,
                              pauses: [
                                  ...prevRentalSessions[index].pauses,
                                  { startTimestamp: rentalSession.timestamp },
                              ],
                          }
                        : prevRentalSession
                )
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] }),
    });
}

export function useResumeRentalSession(onError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (resume) => {
            return await httpClient
                .post('/rentalSession/resume', {
                    sessionId: resume.sessionId,
                    timestamp: resume.timestamp,
                })
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    onError(err.response.data.error.message);
                    return false;
                });
        },
        // client side optimistic update
        onMutate: (rentalSession) => {
            queryClient.setQueryData([RENTAL_SESSION_QUERY_KEY], (prevRentalSessions) =>
                prevRentalSessions?.map((prevRentalSession, index) =>
                    prevRentalSession._id === rentalSession.sessionId
                        ? {
                              ...prevRentalSessions[index],
                              paused: false,
                              pauses: prevRentalSessions[index].pauses.map((pause) =>
                                  !pause.endTimestamp
                                      ? {
                                            ...pause,
                                            endTimestamp: rentalSession.timestamp.toString(),
                                        }
                                      : { ...pause }
                              ),
                          }
                        : prevRentalSession
                )
            );
        },
        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] }),
    });
}

export function useLinkRentalSession(setError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (linkInformation) => {
            return await httpClient
                .post('/rentalSession/link', linkInformation)
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    setError(err.response.data.error.message);
                    return false;
                });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_SALE_BALANCE_QUERY_KEY] });
        },
    });
}

export function useProductSaleRentalSession(onError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (productSale) => {
            return await httpClient
                .post('/rentalSession/productSales', productSale)
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    onError(err.response.data.error.message);
                    return false;
                });
        },

        onSettled: () => queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] }),
    });
}

export function useGetNestedLinkedRentalSessions(ids, onError) {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: [RENTAL_SESSION_QUERY_KEY, ids],
        queryFn: async () => {
            if (ids.length) {
                return await httpClient
                    .get(`/rentalSession/nestedLinkedRentalSessions?ids=${ids?.join('&ids=')}`)
                    .then((result) => {
                        if (result.status === 200) {
                            return result.data;
                        }
                    })
                    .catch((err) => {
                        onError(err.response.data.error.message);
                    });
            } else return [];
        },
    });
}

export function useAddTimeRentalSession(onError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();
    return useMutation({
        mutationFn: async (additionalTime) => {
            return await httpClient
                .post('/rentalSession/addTime', {
                    sessionId: additionalTime.sessionId,
                    endTimestamp: additionalTime.endTimestamp,
                    prePaidCash: additionalTime.prePaidCash,
                    prePaidCredit: additionalTime.prePaidCredit,
                })
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    onError(err.response.data.error.message);
                    return false;
                });
        },

        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [RENTAL_SESSION_SALE_BALANCE_QUERY_KEY] });
        },
    });
}

export function useGetProducts() {
    const httpClient = useHttpClient();

    return useQuery({
        queryKey: [PRODUCT_QUERY_KEY],
        queryFn: async () => {
            return await httpClient.get('/product').then((result) => {
                if (result.status === 200) {
                    return result.data.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });
                }
            });
        },
        refetchInterval: (5 * 60 + 7) * 1000,
    });
}

export function useProductSale(onError) {
    const queryClient = useQueryClient();
    const httpClient = useHttpClient();

    return useMutation({
        mutationFn: async (productSale) => {
            return await httpClient
                .post('/productSales', productSale)
                .then(() => {
                    return true;
                })
                .catch((err) => {
                    onError(err.response.data.error.message);
                    return false;
                });
        },

        onSettled: () =>
            queryClient.invalidateQueries({ queryKey: [PRODUCT_SALE_BALANCE_QUERY_KEY] }),
    });
}
