import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const ScreenDimensionsContext = createContext();

const ScreenDimensionsProvider = ({ children }) => {
    const [screenDimensions, setScreenDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = () => {
            setScreenDimensions({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const screenContextValue = useMemo(
        () => ({
            screenWidth: screenDimensions.width,
            screenHeight: screenDimensions.height,
        }),
        [screenDimensions]
    );

    return (
        <ScreenDimensionsContext.Provider value={screenContextValue}>
            {children}
        </ScreenDimensionsContext.Provider>
    );
};

export const useScreenDimensions = () => {
    return useContext(ScreenDimensionsContext);
};

export default ScreenDimensionsProvider;

export const MOBILE_SCREEN_SIZE = 430;
export const SMALL_SCREEN_SIZE = 500;
