import PaymentMethodInputs from '../../../shared/PaymentMethodInputs/PaymentMethodInputs';
import { Backdrop, CircularProgress } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import {
    calculatePrice,
    calculateSessionMSDuration,
    isFixedType,
    msToMinutes,
    roundValue,
} from '../../rentalSessionUtils';
import { useOrgOptions } from '../../../provider/OrganizationOptionsProvider';
import {
    useRentalCategories,
    useRentals,
    useRentalSessions,
} from '../../../provider/RentalSessionsWrapperProvider';
import Select from 'react-select';
import './LinkRentalSession.css';
import { sendClearAllNotifications } from '../../../shared/utils/tvOverlayUtils';

function LinkRentalSession({ onOpenStateChange, onLink, session }) {
    const [payments, setPayments] = useState({
        cash: 0,
        credit: 0,
    });
    const [selectedSession, setSelectedSession] = useState({
        categoryId: '',
        sessionId: '',
    });
    const { rentalSessionsByCategory, rentalSessions } = useRentalSessions();
    const { rentalsById } = useRentals();
    const { categories, categoriesMap } = useRentalCategories();
    const [isLoading, setIsLoading] = useState(false);
    const { orgOptions, currency } = useOrgOptions();

    const totalPrice = useMemo(() => {
        const menuPrice = session.usedProducts.reduce(
            (partialSum, product) =>
                (
                    parseFloat(partialSum) +
                    product.quantity * parseFloat(product.price.$numberDecimal)
                ).toFixed(2),
            0
        );

        const timerPrice = calculatePrice(
            msToMinutes(
                calculateSessionMSDuration(
                    session.startTimestamp,
                    isFixedType(session.priceType) ? session.endTimestamp : Date.now(),
                    session.pauses
                )
            ),
            session.rentalCategoryId,
            session.rentalTypes,
            session.transferHistory,
            categoriesMap
        );

        return (roundValue(timerPrice, orgOptions) + parseFloat(menuPrice)).toFixed(2);
    }, [session, categoriesMap, orgOptions]);

    const prepaidBalance = useMemo(() => {
        const totalPrepaidCash = parseFloat(session.paymentCash.$numberDecimal);
        const totalPrepaidCredit = parseFloat(session.paymentCredit.$numberDecimal);
        const totalPrepaid = totalPrepaidCash + totalPrepaidCredit;

        return {
            totalPrepaid,
            totalPrepaidCash,
            totalPrepaidCredit,
        };
    }, [session]);

    const handleCancel = useCallback(() => {
        if (!isLoading) {
            onOpenStateChange(false);
        }
    }, [onOpenStateChange, isLoading]);

    const handlePaymentsInput = useCallback(
        (paymentInputs) => {
            setPayments({ ...paymentInputs });
        },
        [setPayments]
    );

    const handleLink = useCallback(async () => {
        setIsLoading(true);
        if (await onLink(session._id, selectedSession.sessionId, payments.cash, payments.credit)) {
            sendClearAllNotifications(rentalsById[session.rentalId]?.address);
            onOpenStateChange(false);
        }
        setIsLoading(false);
    }, [
        onLink,
        session._id,
        session.rentalId,
        selectedSession.sessionId,
        payments.cash,
        payments.credit,
        rentalsById,
        onOpenStateChange,
    ]);

    const handleSelectChange = useCallback(
        (event, action) => {
            setSelectedSession((curState) => ({ ...curState, [action.name]: event?.value }));
        },
        [setSelectedSession]
    );

    const categorySelectOptions = useMemo(() => {
        return categories
            .filter(
                (category) =>
                    rentalSessionsByCategory[category._id] &&
                    !(
                        rentalSessionsByCategory[category._id][session.rentalId] &&
                        Object.keys(rentalSessionsByCategory[category._id]).length === 1
                    )
            )
            .map((category) => {
                return { value: category._id, label: category.name };
            });
    }, [categories, rentalSessionsByCategory, session.rentalId]);

    const rentalSelectOptions = useMemo(() => {
        return rentalSessions
            .filter(
                (liveSession) =>
                    (!selectedSession.categoryId ||
                        liveSession.rentalCategoryId === selectedSession.categoryId) &&
                    liveSession._id !== session._id
            )
            .map((session) => {
                return { value: session._id, label: session.rentalName };
            });
    }, [rentalSessions, selectedSession.categoryId, session._id]);

    const customStyles = useMemo(
        () => ({
            option: (base, { isFocused }) => {
                return {
                    ...base,
                    backgroundColor: isFocused ? 'var(--primary-color)' : 'var(--secondary-blue)',
                };
            },
            menu: (base) => {
                return {
                    ...base,
                    backgroundColor: 'var(--secondary-blue)',
                };
            },
            control: (base) => {
                return {
                    ...base,
                    backgroundColor: 'transparent',
                    width: '100%',
                    border: 'none',
                    boxShadow: 'none',
                };
            },
            container: (base) => {
                return {
                    ...base,
                    borderColor: 'transparent',
                    borderBottom: '1px solid rgb(160, 149, 149)',
                };
            },
        }),
        []
    );

    return (
        <>
            <div className="popUp-wrapper sessionLink-pupUp">
                <div className={'sessionLink-prices'}>
                    <div className="sessionLink-priceSection">
                        <span>Total Price : </span>
                        <span className="LinkPopUp-priceIndicators">
                            {totalPrice}
                            {currency.sign}
                        </span>
                    </div>
                    <div className="sessionLink-priceSection">
                        <span>Price to Pay : </span>
                        <span className="LinkPopUp-priceIndicators">
                            {(parseFloat(totalPrice) - prepaidBalance.totalPrepaid).toFixed(2)}
                            {currency.sign}
                        </span>
                    </div>
                </div>
                <br />
                <div className={'sessionLink-inputs'}>
                    <label htmlFor="rentalCategory">Category:</label>
                    <div className="sessionLink-inputClass">
                        <Select
                            options={categorySelectOptions}
                            isClearable
                            placeholder="Select Category"
                            name="categoryId"
                            id="rentalCategory"
                            onChange={handleSelectChange}
                            classNamePrefix="react-select"
                            className="inputWindows"
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className={'sessionLink-inputs'}>
                    <label htmlFor="rentalSession">Session:</label>

                    <div className="sessionLink-inputClass">
                        <Select
                            options={rentalSelectOptions}
                            isClearable
                            placeholder="Select Session"
                            name="sessionId"
                            id="rentalSession"
                            onChange={handleSelectChange}
                            classNamePrefix="react-select"
                            className="inputWindows"
                            styles={customStyles}
                        />
                    </div>
                </div>

                <div>
                    <PaymentMethodInputs
                        maxTotalValue={parseFloat(totalPrice) - prepaidBalance.totalPrepaid}
                        onInput={handlePaymentsInput}
                        strictMode={false}
                    />
                </div>

                <div className="sessionPopUp-actions">
                    <button
                        className="popUp-button cancel"
                        disabled={isLoading}
                        onClick={handleCancel}>
                        Cancel
                    </button>
                    <button
                        className="popUp-button start"
                        onClick={handleLink}
                        disabled={!selectedSession.sessionId || isLoading}>
                        Link {isLoading ? <CircularProgress className="circularProgress" /> : null}
                    </button>
                </div>
            </div>

            <Backdrop className="alert-backdrop" open={true} onClick={handleCancel} />
        </>
    );
}

export default LinkRentalSession;
